import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {todoService} from 'api/services';
import {todoSendTaskWhite} from 'assets';
import {UserProfileTodoBloc} from 'components';
import {PORTAL_PATHS} from 'const';
import {PFButton, PFTabsContainer} from 'shared/components';
import PFSafeHtmlRenderer from 'shared/components/PF-SafeHtmlRenderer';
import {useUserProfile} from 'shared/hooks';
import {TodoModalActionType, TodoModalType, TodoType} from 'types';
import {Amplitude, getFullName, parseUrlQuery, useErrorHelper} from 'utils';

import iconBack from '../../../../assets/icon-arrow-back.svg';
import {SetTodoModal} from '../../../../components/UserProfile/UserProfileTodoBloc/components';

import './style.scss';

type StateTodosType = {
	current: TodoType[] | null;
	completed: TodoType[] | null;
	dismissed: TodoType[] | null;
};

const initialTodoState = {
	current: null,
	completed: null,
	dismissed: null,
};

const initialTodoModalState: TodoModalType = {item: null, isVisibleModal: false};

const ViewAllTodos = () => {
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const {token} = parseUrlQuery(document.location);
	const fetchUserInfo = useUserProfile();
	const [todoItems, setTodoItems] = useState<StateTodosType>(initialTodoState);
	const [name, setName] = useState<string>('User without name');
	const [todoModalInfo, setTodoModalInfo] = useState<TodoModalType>(initialTodoModalState);

	const fetchUser = async userToken => {
		// eslint-disable-next-line no-useless-catch
		try {
			const data = await fetchUserInfo(userToken);
			const fullName = getFullName(data?.user?.firstName, data?.user?.lastName);
			setName(fullName);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const getTodosData = async userToken => {
		try {
			const response = await todoService.getTodoByToken(userToken);
			const newTodoState = {
				current: response?.data.filter(item => !item.isCompleted && !item.isDismissed),
				completed: response?.data.filter(item => item.isCompleted),
				dismissed: response?.data.filter(item => item.isDismissed),
			};
			setTodoItems(newTodoState);
		} catch (e) {
			errorHelper(e);
			throw e;
		}
	};

	const goBack = () => {
		Amplitude.track('Opened_Client_Profile', {
			source: 'To Do List',
		});
		navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${token}`);
	};

	const updateCallback = () => {
		getTodosData(token);
	};

	const onCancelTodoModal = () => {
		setTodoModalInfo(initialTodoModalState);
	};

	const onSendTask = () => {
		setTodoModalInfo({
			item: {token, title: null, text: null, deadline: null, action: 'create'},
			isVisibleModal: true,
		});
	};

	useEffect(() => {
		getTodosData(token);
		fetchUser(token);
	}, [token]);

	return (
		<div className="view-all">
			<div className="view-all__container">
				<PFButton
					className="w-auto px-0 view-all__container-backButton"
					prefixIcon={<ReactSVG wrapper="span" className="me-2" src={iconBack} />}
					variant="plain"
					onClick={goBack}
					type="button">
					Back
				</PFButton>
				<div className="view-all__container-titleWrapper">
					<div className="view-all__container-titleWrapper-title">{`${decodeURI(
						name,
					)}'s To Do List`}</div>
					<PFButton
						className="px-0 view-all__container-titleWrapper-sendTaskButton"
						prefixIcon={
							<ReactSVG wrapper="span" className="me-2" src={todoSendTaskWhite} />
						}
						variant="primary"
						onClick={onSendTask}
						type="button">
						Send Task
					</PFButton>
				</div>
				<PFTabsContainer labels={['Current', 'Completed', 'Dismissed']}>
					<UserProfileTodoBloc
						showDescription
						shouldColorDeadlines
						userToken={token}
						todoItems={
							todoItems.current && todoItems.current.length > 0
								? todoItems.current
								: 'You have not sent any tasks to the user. Click the button above to add one.'
						}
						updateCallback={updateCallback}
						isVisibleSendTaskButton={false}
						isCompact={false}
					/>
					<UserProfileTodoBloc
						showDescription
						userToken={token}
						todoItems={
							todoItems.completed && todoItems.completed.length > 0
								? todoItems.completed
								: 'Tasks marked by the client as completed will appear here.'
						}
						updateCallback={updateCallback}
						isVisibleSendTaskButton={false}
						isCompact={false}
					/>
					<UserProfileTodoBloc
						showDescription
						userToken={token}
						todoItems={
							todoItems.dismissed && todoItems.dismissed.length > 0
								? todoItems.dismissed
								: 'Tasks deimissed by the client will appear here.'
						}
						updateCallback={updateCallback}
						isVisibleSendTaskButton={false}
						isCompact={false}
					/>
				</PFTabsContainer>
			</div>
			{todoModalInfo.item && (
				<SetTodoModal
					token={todoModalInfo.item.token}
					title={todoModalInfo.item.title}
					text={todoModalInfo.item.text}
					deadline={todoModalInfo.item.deadline}
					action={todoModalInfo.item.action}
					visible={todoModalInfo.isVisibleModal}
					className="todoModal"
					onCancel={onCancelTodoModal}
					updateCallback={updateCallback}
				/>
			)}
		</div>
	);
};

export default ViewAllTodos;
