import React, {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';

import {referBusinessesService} from 'api/services';
import PFButton from 'shared/components/PF-Button';
import PFPopup from 'shared/components/PF-Popup';
import PFStepsContainer from 'shared/components/PF-StepsContainer';
import {useAppDispatch, useAppSelector} from 'store';
import {resetReferBusinessPopup, setCloseReferPopup} from 'store/actions';
import {
	selectIsOpenReferPopup,
	selectReferPopupAdvisorCheckedKeys,
	selectReferPopupClientsCheckedKeys,
	selectUserFlowNameReferPopup,
} from 'store/selectors';
import {Amplitude, ToastDispatcher} from 'utils';

import {WhoIsReferred} from '../../types/enums';

import FormInputKeys from './components/constants';
import StepClients from './components/StepClients';
import StepDetails from './components/StepDetails';
import StepRecipients from './components/StepRecipients';

import './style.scss';
import style from './style.module.scss';

const ReferBusinessPopup: React.FC<{
	className?: string;
}> = ({className}) => {
	const dispatch = useAppDispatch();
	const isOpen = useAppSelector(selectIsOpenReferPopup);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const clientsCheckedKeys = useAppSelector(selectReferPopupClientsCheckedKeys);
	const advisorCheckedKeys = useAppSelector(selectReferPopupAdvisorCheckedKeys);
	const userFlowName = useAppSelector(selectUserFlowNameReferPopup);
	const [hintValue, setHintValue] = useState(0);
	const [step, setStep] = useState(0);

	const formProps = useForm({
		mode: 'onChange',
	});
	const {
		register,
		trigger,
		getValues,
		watch,
		formState,
		setValue,
		control,
		unregister,
		setError,
		handleSubmit,
		...props
	} = formProps;

	const handleClose = () => {
		dispatch(resetReferBusinessPopup());
		dispatch(setCloseReferPopup());
		setValue(FormInputKeys.whoIsReferred, String(WhoIsReferred.YourClients));
		setValue(FormInputKeys.referringToBusinessesType, null);
		setValue(FormInputKeys.description, '');
		setValue(FormInputKeys.formatteddescription, '');
		setValue(FormInputKeys.firstName, '');
		setValue(FormInputKeys.lastName, '');
		setValue(FormInputKeys.email, '');
		setValue(FormInputKeys.phoneNumber, '');
		setStep(0);
	};

	const onRefer = async valueObj => {
		const body = {
			userTokens:
				valueObj[FormInputKeys.whoIsReferred] === WhoIsReferred.YourClients
					? clientsCheckedKeys
					: null,
			unregisteredUsers:
				valueObj[FormInputKeys.whoIsReferred] === WhoIsReferred.UnregisteredUsers
					? [
							{
								firstName: valueObj[FormInputKeys.firstName],
								lastName: valueObj[FormInputKeys.lastName],
								email: valueObj[FormInputKeys.email],
								phoneNumber: valueObj[FormInputKeys.phoneNumber]?.formattedValue,
							},
					  ]
					: null,
			type: Number(valueObj[FormInputKeys.referringToBusinessesType]),
			description: valueObj[FormInputKeys.formatteddescription]
				? valueObj[FormInputKeys.formatteddescription].trim()
				: valueObj[FormInputKeys.description].trim(),
			advisorTokens: advisorCheckedKeys,
			businessCompany: null,
		};

		try {
			setIsSubmitting(true);
			await referBusinessesService.setReferBusinesses(body);

			Amplitude.track('Business_Referred', {
				source: userFlowName === 'Current' ? 'Referred clients' : userFlowName,
				type_of_business: body.type,
				who_is_referred: valueObj[FormInputKeys.whoIsReferred],
				clients: body.userTokens !== null ? body.userTokens : body.unregisteredUsers,
				recipients: advisorCheckedKeys,
			});
			Amplitude.incrementUserProperty('total_referred_business');

			handleClose();
			ToastDispatcher.success(`Business referred.`);
			// eslint-disable-next-line no-useless-catch
		} catch (e: any) {
			ToastDispatcher.error(`Business has not been referred. Please, try again.`);
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === FormInputKeys.whoIsReferred) {
				await trigger(FormInputKeys.whoIsReferred);
			}
			if (name === FormInputKeys.firstName) {
				await trigger(FormInputKeys.firstName);
			}
			if (name === FormInputKeys.lastName) {
				await trigger(FormInputKeys.lastName);
			}
			if (name === FormInputKeys.email) {
				await trigger(FormInputKeys.email);
			}
			if (name === FormInputKeys.phoneNumber) {
				await trigger(FormInputKeys.phoneNumber);
			}
			if (name === FormInputKeys.description) {
				setHintValue(data.description.length);
				await trigger(FormInputKeys.description);
			}
			if (name === FormInputKeys.referringToBusinessesType) {
				await trigger(FormInputKeys.referringToBusinessesType);
			}
			if (name === FormInputKeys.description) {
				await trigger(FormInputKeys.description);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	useEffect(() => {
		setValue(FormInputKeys.whoIsReferred, String(WhoIsReferred.YourClients));
		return () => {
			dispatch(resetReferBusinessPopup());
			setValue(FormInputKeys.whoIsReferred, String(WhoIsReferred.YourClients));
			setValue(FormInputKeys.referringToBusinessesType, null);
			setValue(FormInputKeys.description, '');
			setValue(FormInputKeys.formatteddescription, '');
			setValue(FormInputKeys.firstName, '');
			setValue(FormInputKeys.lastName, '');
			setValue(FormInputKeys.email, '');
			setValue(FormInputKeys.phoneNumber, '');
			setStep(0);
		};
	}, []);

	// =========================== labels ==============

	const getLabels = (flow: 'Current' | 'Menu' | 'Network') => {
		if (flow === 'Current') {
			return ['Details', 'Recipients'];
		}
		if (flow === 'Network') {
			return ['Clients', 'Details'];
		}
		return ['Clients', 'Details', 'Recipients'];
	};

	const labels = useMemo(() => getLabels(userFlowName), [userFlowName]);

	// =========================== steps ==============

	const handleNext = () => {
		if (step < 2) setStep(step + 1);
	};

	const handleBack = () => {
		if (step > 0) setStep(step - 1);
	};

	const onCancelOrPrev = () => {
		if (step > 0) {
			handleBack();
		} else {
			dispatch(setCloseReferPopup());
		}
	};

	// =========================== validation step ==============

	const isValidStep = (flowName: 'Current' | 'Menu' | 'Network', currentStep) => {
		let result = false;

		switch (flowName) {
			case 'Menu': {
				if (getValues(FormInputKeys.whoIsReferred) === String(WhoIsReferred.YourClients)) {
					if (
						(currentStep === 0 || currentStep === 1) &&
						!!clientsCheckedKeys?.length &&
						formState.isValid
					) {
						result = true;
					} else if (
						currentStep === 2 &&
						!!clientsCheckedKeys?.length &&
						!!advisorCheckedKeys?.length &&
						formState.isValid
					) {
						result = true;
					} else {
						result = false;
					}
				}
				if (
					getValues(FormInputKeys.whoIsReferred) ===
					String(WhoIsReferred.UnregisteredUsers)
				) {
					if ((currentStep === 0 || currentStep === 1) && formState.isValid) {
						result = true;
					} else if (
						currentStep === 2 &&
						!!advisorCheckedKeys?.length &&
						formState.isValid
					) {
						result = true;
					} else {
						result = false;
					}
				}
				break;
			}
			case 'Current': {
				if (currentStep === 0 && !!clientsCheckedKeys?.length && formState.isValid) {
					result = true;
				} else if (
					currentStep === 1 &&
					!!clientsCheckedKeys?.length &&
					!!advisorCheckedKeys?.length &&
					formState.isValid
				) {
					result = true;
				} else {
					result = false;
				}
				break;
			}
			case 'Network': {
				if (getValues(FormInputKeys.whoIsReferred) === String(WhoIsReferred.YourClients)) {
					if (
						!!advisorCheckedKeys?.length &&
						!!clientsCheckedKeys?.length &&
						formState.isValid
					) {
						result = true;
					} else {
						result = false;
					}
				}
				if (
					getValues(FormInputKeys.whoIsReferred) ===
					String(WhoIsReferred.UnregisteredUsers)
				) {
					if (!!advisorCheckedKeys?.length && formState.isValid) {
						result = true;
					} else {
						result = false;
					}
				}
				break;
			}
			default: {
				break;
			}
		}
		return result;
	};

	const isValid = useMemo(
		() => isValidStep(userFlowName, step),
		[userFlowName, step, clientsCheckedKeys, advisorCheckedKeys, formState.isValid],
	);

	return (
		<PFPopup
			isShow={isOpen}
			handleClose={handleClose}
			isShowFooter={false}
			submitBtnText="Recommend"
			primaryVariant="alert"
			isCloseButton
			isShowCancelBtn={false}
			title="Refer Business"
			onSubmit={() => false}
			className={{
				root: `${style.referPopup} referPopup`,
				paper: `${style.referPopup_paper} referPopup_paper`,
				contentBody: style.referPopup_contentBody,
			}}>
			<form className={style.referPopup_form}>
				{userFlowName === 'Menu' && (
					<PFStepsContainer labels={labels} step={step}>
						<StepClients formProps={formProps} isOpen={isOpen} key="Clients" />
						<StepDetails formProps={formProps} hintValue={hintValue} key="Details" />
						<StepRecipients isOpen={isOpen} key="Recipients" />
					</PFStepsContainer>
				)}
				{userFlowName === 'Current' && (
					<PFStepsContainer labels={labels} step={step}>
						<StepDetails formProps={formProps} hintValue={hintValue} key="Details" />
						<StepRecipients isOpen={isOpen} key="Recipients" />
					</PFStepsContainer>
				)}
				{userFlowName === 'Network' && (
					<PFStepsContainer labels={labels} step={step}>
						<StepClients formProps={formProps} isOpen={isOpen} key="Clients" />
						<StepDetails formProps={formProps} hintValue={hintValue} key="Details" />
					</PFStepsContainer>
				)}
				<div className={style.referPopup_footer}>
					<div className={style.buttonsWrapper}>
						<PFButton onClick={onCancelOrPrev} variant="secondary" type="button">
							{step > 0 ? 'Back' : 'Cancel'}
						</PFButton>
						<PFButton
							disabled={isSubmitting || !isValid}
							variant="primary"
							onClick={
								labels?.length && step < labels.length - 1
									? handleNext
									: handleSubmit(onRefer)
							}
							className={style.referButton}
							type="button">
							{labels?.length && step < labels.length - 1 ? 'Proceed' : 'Refer'}
						</PFButton>
					</div>
				</div>
			</form>
		</PFPopup>
	);
};

export default ReferBusinessPopup;
