import {NavigateFunction} from 'react-router-dom';

import {advisorClientsService, financialReportsService} from 'api/services';
import {
	active,
	defaultAvatar,
	deleted,
	dismiss,
	inactive,
	infoGreen,
	infoGrey,
	infoRed,
	insurance,
	leadsInvite,
	leadsRequest,
	meetingRequest,
	message,
	pause,
	referred,
	reportRequest,
	restore,
	role,
	successBlue,
	successGreen,
	successYellow,
	superadminAvatar,
} from 'assets';
import {Amplitude, ToastDispatcher} from 'utils';

import PORTAL_PATHS from '../../../const/url-paths/portal';
import {
	AdvisorClientType,
	AdvisorNotificationEnum,
	AdvisorNotificationGroupEnum,
} from '../../../types';

const USER_NOT_REACHABLE_MESSAGE = 'This user is not reachable anymore.';
const INFORMATION_NOT_REACHABLE_MESSAGE = 'The requested information no longer exists.';

export const notificationItemProps = {
	[AdvisorNotificationGroupEnum.SuperAdminActivity]: {
		[AdvisorNotificationEnum.AdvisorIsActive]: {
			icon: active,
			avatar: superadminAvatar,
			isAction: false,
			action: null,
			actionButtonName: null,
		},
		[AdvisorNotificationEnum.AdvisorIsInactive]: {
			icon: inactive,
			avatar: superadminAvatar,
			isAction: false,
			action: null,
			actionButtonName: null,
		},
		[AdvisorNotificationEnum.AdvisorChangeEmail]: {
			icon: successBlue,
			avatar: superadminAvatar,
			isAction: false,
			action: null,
			actionButtonName: null,
		},
		[AdvisorNotificationEnum.CompanyIsDeleted]: {
			icon: infoRed,
			avatar: superadminAvatar,
			isAction: true,
			action: (
				navigate: NavigateFunction,
				_userToken?: string,
				onSuccess?: () => void,
			): void => {
				navigate(`${PORTAL_PATHS.OTHER_PATHS.MY_PROFILE_EDIT}`);
				onSuccess?.();
			},
			actionButtonName: 'Edit Profile',
		},
	},
	[AdvisorNotificationGroupEnum.Client]: {
		[AdvisorNotificationEnum.ClientIsDeleted]: {
			icon: deleted,
			avatar: defaultAvatar,
			isAction: false,
			action: null,
			actionButtonName: null,
		},
	},
	[AdvisorNotificationGroupEnum.Advisor]: {
		[AdvisorNotificationEnum.ReferringToBusiness]: {
			icon: referred,
			avatar: defaultAvatar,
			isAction: true,
			action: (
				navigate: NavigateFunction,
				_userToken?: string,
				onSuccess?: () => void,
			): void => {
				navigate(`${PORTAL_PATHS.SIDEBAR.REFERRED_TO_ME}`);
				onSuccess?.();
			},
			actionButtonName: 'Review',
		},
		[AdvisorNotificationEnum.AdvisorPartnerAdded]: {
			icon: successBlue,
			avatar: defaultAvatar,
			isAction: false,
			action: null,
		},
	},
	[AdvisorNotificationGroupEnum.Opportunity]: {
		[AdvisorNotificationEnum.OpportunityIsDismissed]: {
			icon: dismiss,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});

							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
		[AdvisorNotificationEnum.OpportunityIsActioned]: {
			icon: successGreen,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
		[AdvisorNotificationEnum.OpportunityIsRestored]: {
			icon: restore,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
		[AdvisorNotificationEnum.OpportunityIsDismissedByOtherAdvisor]: {
			icon: dismiss,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
		[AdvisorNotificationEnum.OpportunityIsActionedByOtherAdvisor]: {
			icon: successGreen,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
		[AdvisorNotificationEnum.OpportunityIsRestoredByOtherAdvisor]: {
			icon: restore,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
		[AdvisorNotificationEnum.OpportunityLargeCashDeposit]: {
			icon: infoGreen,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
		[AdvisorNotificationEnum.OpportunityChildTaxDeposit]: {
			icon: infoGreen,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
		[AdvisorNotificationEnum.OpportunityNewAccountConnected]: {
			icon: infoGrey,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
	},
	[AdvisorNotificationGroupEnum.Insurance]: {
		[AdvisorNotificationEnum.InsuranceInfoRequest]: {
			icon: insurance,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
	},
	[AdvisorNotificationGroupEnum.ToDo]: {
		[AdvisorNotificationEnum.ToDoIsDeleted]: {
			icon: deleted,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
		[AdvisorNotificationEnum.ToDoIsCompleted]: {
			icon: successYellow,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
		[AdvisorNotificationEnum.ToDoIsRestored]: {
			icon: restore,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
		[AdvisorNotificationEnum.ToDoIsDismissed]: {
			icon: dismiss,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Profile',
		},
	},
	[AdvisorNotificationGroupEnum.AdvisorClient]: {
		[AdvisorNotificationEnum.AdvisorClientAddRole]: {
			icon: role,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.WaitingApproval) {
							navigate(`${PORTAL_PATHS.SIDEBAR.LEADS}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'View in Leads List',
		},
		[AdvisorNotificationEnum.AdvisorClientRemoveRole]: {
			icon: role,
			avatar: defaultAvatar,
			isAction: false,
			action: null,
			actionButtonName: null,
		},
		[AdvisorNotificationEnum.AdvisorClientFinReportReceived]: {
			icon: reportRequest,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						await financialReportsService.getFinancialReportsByToken(userToken);

						navigate(`${PORTAL_PATHS.OTHER_PATHS.VIEW_ALL_REPORTS}?token=${userToken}`);
						onSuccess?.();

						return;
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(INFORMATION_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: 'Review Client Reports',
		},
		[AdvisorNotificationEnum.AdvisorClientMeetingRequest]: {
			icon: meetingRequest,
			avatar: defaultAvatar,
			isAction: true,
			action: async (
				navigate: NavigateFunction,
				userToken?: string,
				onSuccess?: () => void,
			) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							Amplitude.track('Opened_Client_Profile', {
								source: 'Notifications',
							});
							navigate(`${PORTAL_PATHS.OTHER_PATHS.USER_PROFILE}#${userToken}`);
							onSuccess?.();

							return;
						}

						if (profile.data.type === AdvisorClientType.WaitingApproval) {
							navigate(`${PORTAL_PATHS.SIDEBAR.LEADS}`);
							onSuccess?.();

							return;
						}
					}

					throw new Error('No user token');
				} catch (err) {
					ToastDispatcher.error(USER_NOT_REACHABLE_MESSAGE);
				}
			},
			actionButtonName: async (userToken?: string) => {
				try {
					if (userToken) {
						const profile = await advisorClientsService.getUserProfile({userToken});

						if (profile.data.type === AdvisorClientType.Current) {
							return 'View Client Profile';
						}

						if (profile.data.type === AdvisorClientType.WaitingApproval) {
							return 'Review Leads List';
						}
					}
					return null;
				} catch (err) {
					return null;
				}
			},
		},
		[AdvisorNotificationEnum.PotentialAdvisorClientMeetingRequest]: {
			icon: meetingRequest,
			avatar: defaultAvatar,
			isAction: false,
			action: null,
			actionButtonName: null,
		},
	},
};
