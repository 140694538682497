import React, {useEffect, useState} from 'react';
import View from '../View';
import {useNavigate, useLocation} from 'react-router-dom';
import {PFMobileMenu, PFHeader, PFLoader} from '../../shared/components';
import ROUTES from '../../routes';
import COMMON_PATHS from '../../const/url-paths/common';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import PUBLIC_PATHS from '../../const/url-paths/public';
import {useSetFreshdeskRestoreId} from '../../shared/hooks/users';
import {useAppSelector} from '../../store';
import {SubscriptionSelectors, myProfileSelectors} from '../../store/selectors';
import PORTAL_PATHS from '../../const/url-paths/portal';
import './style.scss';
import {isCompletedProfile} from '../../utils';
import {AdvisorsRole} from '../../types/enums';
// After logging in. This is private.
import { ProductFruits, useProductFruitsApi } from 'react-product-fruits';

export default function Portal() {
	const navigate = useNavigate();
	const location = useLocation();
	const auth = useSelector(state => state.general?.auth?.authToken);
	const hasSubscription = useSelector(SubscriptionSelectors.selectHasSubscription);
	//headerRoutes contains all the routes which are allowed to have PF-ProgressHeader, this includes common routes as long as the user is authenticated
	const headerRoutes = ROUTES.map(
		item => (item.private && item.path) || (auth && COMMON_PATHS.ERROR),
	);
	//showHeader here checks if the current location is within the headerRoutes
	const showHeader = headerRoutes.includes(location.pathname);

	const isHeaderHidden =
		location.pathname === PORTAL_PATHS.OTHER_PATHS.SUBSCRIPTION_STATUS ||
		location.pathname === PORTAL_PATHS.OTHER_PATHS.RESTORE_PASSWORD ||
		location.pathname === PORTAL_PATHS.OTHER_PATHS.SET_PASSWORD_BY_CODE;

	const isPublicPaths = Object.values(PUBLIC_PATHS).includes(location.pathname);
	const isInvitation = location.pathname === PORTAL_PATHS.OTHER_PATHS.ECO_ADVISOR_PROFILE; // === 'invitation'
	const saveRestoreId = useSetFreshdeskRestoreId();
	const [isDeepLink, setIsDeepLink] = useState(false);

	if (auth && isPublicPaths && !isInvitation) {
		navigate('/');
	}

	const role = useAppSelector(state => state.general?.auth?.roles); // ! new

	// ------------------------------- Freshdesk ---------------------------
	const user = useAppSelector(myProfileSelectors.selectMyProfileData);

	const initFreshChat = () => {
		const data = {
			token: '9fdf86c0-36df-4a3b-b247-3afc41b42bdf',
			host: 'https://wchat.freshchat.com',
			siteId: 'portal',
			externalId: user.token,
			restoreId: user.freshdeskId ? user.freshdeskId : null,
			locale: 'en',
			faqTags: {
				tags: ['portal'],
				filterType: 'category',
			},
		};
		try {
			window.fcWidget.init(data);
		} catch (e) {
			throw e;
		}
	};

	const freshChatUserInit = () => {
		// если понадобится телефон то разкоменти
		// const fullPhone = user.advisor.phoneNumber.split(" ");
		// const phoneCountryCode = fullPhone.shift();
		// const phone = fullPhone.join("");

		window.fcWidget.user.get(function (resp) {
			const status = resp && resp.status;
			if (status !== 200) {
				const prop = {
					firstName: user.advisor.firstName,
					lastName: user.advisor.lastName,
					email: user.advisor.email,
					// phone: phone,
					// phoneCountryCode: phoneCountryCode,
					restoreId: user?.freshdeskId ? user?.freshdeskId : null,
				};

				window.fcWidget.user.setProperties(prop);
			}
		});
	};

	const userInfo = {
		...user,
		username: user?.firstName || "",
		firstname: user?.firstName || "",
		lastname: user?.lastName || "",		
	  };
	  
	useEffect(() => {
		const listener = resp => {
			const status = resp && resp.status,
				data = resp && resp.data;
			if (status === 200) {
				if (data.restoreId) {
					saveRestoreId({
						freshdeskId: data.restoreId,
					});
				}
			}
		};

		if (window.fcWidget) {
			window.fcWidget.on('user:created', listener);
		}

		return () => {
			if (window.fcWidget) window.fcWidget.off('user:created', listener);
		};
	}, [window.fcWidget]);

	useEffect(() => {
		if (user && window.fcWidget) {
			if (role && role[0] !== AdvisorsRole.SuperAdmin) {
				initFreshChat();
				freshChatUserInit();
			}
		}
	}, [user, window.fcWidget]);
	// ------------------------------- Freshdesk end ---------------------------

	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	useEffect(() => {
		if (user && !isCompletedProfile(user?.advisor)) {
			setIsDeepLink(false);
		} else {
			setIsDeepLink(true);
		}
	}, [user]);

	useProductFruitsApi(api => {
		if (user && !isCompletedProfile(user?.advisor)){
			api.tours.tryStartTour(82382);
		}        
	}, [user]);

	return (
		<div
			className={classNames('pf-portal', {
				hideHeader: isHeaderHidden,
			})}>
			{hasSubscription !== null ? (
				<>
					{(showHeader || (auth && isInvitation)) && (
						<PFHeader variant="portal" showNavigation isDeepLink={isDeepLink} />
					)}
					<div className="pf-portal__container">
						<PFMobileMenu className="d-xl-none" isDeepLink={isDeepLink} />
						<View isAuth={!!auth} role={role} />
						<ProductFruits workspaceCode="M7zg66tIApKdlELk" language="en" user={userInfo} />
					</div>
				</>
			) : (
				<PFLoader />
			)}
		</div>
	);
}
