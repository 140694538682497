import React, {useState} from 'react';
import {useForm} from 'react-hook-form';

import classNames from 'classnames';
import PFButton from 'shared/components/PF-Button';
import PFPopup from 'shared/components/PF-Popup';
import PFTextarea from 'shared/components/PF-Textarea';

import {generateValidator, validations} from '../../../../../utils';

import style from './style.module.scss';

const DismissReferPopup: React.FC<{
	visible: boolean;
	onSubmit: () => void;
	onCancel: () => void;
	onRejectReasonChange?: (value) => void;
	title: string;
	className?: string;
}> = ({visible, onSubmit, onCancel, title, className, onRejectReasonChange}) => {
	const {register, formState} = useForm({
		mode: 'onChange',
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [hintValue, setHintValue] = useState(0);
	const descriptionHint = (
		<div className={classNames('counter-hint', {error: hintValue > 120})}>{hintValue}/120</div>
	);
	const onDismiss = async () => {
		try {
			setIsSubmitting(true);
			await onSubmit();
			onCancel();
			// eslint-disable-next-line no-useless-catch
		} catch (e: any) {
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<PFPopup
			isShow={visible}
			handleClose={onCancel}
			isShowFooter={false}
			submitBtnText="Reject"
			primaryVariant="alert"
			isCloseButton
			isShowCancelBtn={false}
			title={title}
			onSubmit={() => false}
			className={{root: className}}>
			<PFTextarea
				{...register('note', {
					validate: generateValidator(true, validations.textFields.opportunitiesNote),
					onChange: evt => {
						setHintValue(evt.target.value.length);
						onRejectReasonChange?.(evt.target.value);
					},
				})}
				// @ts-ignore
				showAsterisk
				placeholder="Explain the reason you reject this referral"
				label="State the reason"
				hint={descriptionHint}
				error={formState.errors?.title?.message && formState.errors?.title?.message}
			/>
			<div className={style.modal_footer}>
				<div className={style.modal_footer__buttonsWrapper}>
					<PFButton
						disabled={!formState.isValid || isSubmitting}
						variant="alert"
						onClick={onDismiss}
						className={style.modal_footer__buttonsWrapper__clearButton}
						type="button">
						Decline
					</PFButton>
					<PFButton onClick={onCancel} variant="secondary" type="button">
						Cancel
					</PFButton>
				</div>
			</div>
		</PFPopup>
	);
};

export default DismissReferPopup;
