import React, {useState, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {Table, Checkbox} from 'rsuite';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import {setSidebar, setSideModal} from '../../../store/actions';
import PFSideModal from '../PF-SideModal';
import {
	CurrentModal,
	LeadsModal,
	ConsumersModal,
	CompaniesModal,
	CompanyProfileModal,
	ApiModal,
	EcosystemModal,
} from '../../../components';
import {
	BIG_MOBILE_SCREEN_SIZE,
	DEFAULT_PAGE,
	HEADER_HEIGHT,
	MOBILE_HEADER_HEIGHT,
	PAGINATION_BAR_HEIGHT,
} from '../../../const';
import {setCheckedData, setSelectedTableRowData} from '../../../store/actions';
import MenuItem from '@mui/material/MenuItem';
import {Select} from '@mui/material';
import './style.scss';
import {setTableDisplayLength, setTablePage, setTableSort} from '../../../store/actions';
import {buildDefaultURLParams, updateURL} from '../../../utils';
import {GetIsSidebarOpen, GetIsSideModalOpen} from '../../../store/selectors/UISetings';
import {useAppSelector} from '../../../store';
import PFLoader from '../PF-Loader';
import {tableSelectors} from '../../../store/selectors';
import {useMediaQuery} from 'react-responsive';
import ReferredToMeModal from '../../../components/ReferredToMeModal';
import moment from 'moment';

const PFTable = ({
	tableData,
	tableColumns,
	modal,
	fetchMoreData,
	isLoading,
	fullTableDataLength,
	checkedKeys,
	update,
	updateCallback,
	updateDataForUseOnModal,
	parseUrlQuery,
	emptyComponent,
	hasCurrentCountRestriction = false,
	hasLeadsViewRestriction = false,
	switchPageConditionRunner = undefined,
}) => {
	const dispatch = useDispatch();
	const isSideModalOpen = GetIsSideModalOpen();
	const isSideBarOpen = GetIsSidebarOpen();
	const urlParams = parseUrlQuery(document.location);
	const rowData = useAppSelector(tableSelectors.selectSelectedTableRow);
	const tablePage = useAppSelector(tableSelectors.selectTablePage);
	const pageLength = useAppSelector(tableSelectors.selectTableDisplayLength);
	const {Column, HeaderCell, Cell} = Table;
	const [tableHeaderHeight, setTableHeaderHeight] = useState(80);
	const [rowHeight, setRowHeight] = useState(80);
	const tableRef = useRef();
	const isMobileScreen = useMediaQuery({query: '(max-width: 992px)'});
	const isUpgradePlanBarTwoRows = useMediaQuery({query: '(max-width: 576px)'});
	const isUpgradePlanBarThreeRows = useMediaQuery({query: '(max-width: 530px)'});

	//! <-- для множественных ключей
	{
		/*
		JSON.stringify(
			dataKey.split('/').reduce((acc, key) => {
				acc[key] = rowData[key];
				return acc;
			}, {}),
		)
	*/
	}

	const CheckCell = ({rowData, onChange, checkedKeys, dataKey, ...props}) => (
		<Cell {...props} style={{padding: 0}}>
			<div style={{lineHeight: '46px'}}>
				<Checkbox
					value={rowData[dataKey]} //! <-- для множественных ключей
					inline
					onChange={onChange}
					checked={checkedKeys.some(item => item === rowData[dataKey])}
				/>
			</div>
		</Cell>
	);

	const handleCheckAll = (value, checked) => {
		const checkedKeys = checked ? tableData.map(item => item.token) : []; //! <-- для множественных ключей надо менять map
		dispatch(setCheckedData(checkedKeys));
	};

	const handleCheck = (value, checked) => {
		const nextCheckedKeys = checked
			? [...checkedKeys, value]
			: checkedKeys.filter(item => item !== value);
		dispatch(setCheckedData(nextCheckedKeys));
	};

	let checked = false;
	let indeterminate = false;

	if (tableData && checkedKeys.length === tableData.length && tableData.length !== 0) {
		checked = true;
	} else if (checkedKeys.length === 0) {
		checked = false;
	} else if (tableData && checkedKeys.length > 0 && checkedKeys.length < tableData.length) {
		indeterminate = true;
	}

	const checkMobileMenu = () => {
		if (
			!isSideBarOpen &&
			Math.max(window.screen.width, window.innerWidth) < BIG_MOBILE_SCREEN_SIZE
		) {
			dispatch(setSidebar(false));
		}
	};

	const handleClickOnTableRow = (row, event) => {
		if (row.isDeleted) return;
		if (
			event.target.nodeName !== 'SPAN' &&
			event.target.nodeName !== 'BUTTON' &&
			event.target.nodeName !== 'svg' &&
			event.target.nodeName !== 'A' &&
			event.target.nodeName !== 'path' &&
			!event.target.className.includes('specBadge') &&
			!event.target.className.includes('tagBadge')
		) {
			dispatch(setSelectedTableRowData(row));

			if (!isSideModalOpen) {
				dispatch(setSideModal(true));
			}

			if (!isSideBarOpen) dispatch(setSidebar(true));
		}
	};

	const modalToggle = id => {
		dispatch(setSideModal(!isSideModalOpen));
	};

	const rowClassName = rowData => {
		if (rowData?.isDeleted === true) return 'deleted';
		let className = 'pointer';
		if (rowData?.isBlocked) className += ' blocked';
		if (rowData?.isNewCompany) className += ' newCompany';
		if (rowData?.hasOwnProperty('action') && !rowData?.action && rowData?.lastViewed && moment().diff(moment(rowData.lastViewed)) > 24 * 60 * 60 * 1000) {
			className += ' highlighted';
		}
		return className;
	};

	useEffect(() => {
		dispatch(setSideModal(false));
	}, [modal]);

	useEffect(() => {
		if (Object.keys(urlParams).length !== 0) {
			dispatch(setTablePage(Number(urlParams.page)));
			dispatch(setTableDisplayLength(Number(urlParams.itemsCount)));
		}
	}, []);

	useEffect(() => {
		const screenWidth = window.innerWidth;
		if (screenWidth <= 360) {
			setTableHeaderHeight(60);
			setRowHeight(60);
		} else {
			setTableHeaderHeight(80);
			setRowHeight(80);
		}
	}, [tableData]);

	useEffect(() => {
		const resizeListener = () => {
			const screenWidth = window.innerWidth;
			if (screenWidth <= 360) {
				setTableHeaderHeight(60);
				setRowHeight(60);
			} else {
				setTableHeaderHeight(80);
				setRowHeight(80);
			}
			checkMobileMenu();
		};
		const popstateListener = () => {
			const data = parseUrlQuery(document.location);
			const {page, itemsCount, predicate, reverse, searchText} = buildDefaultURLParams(data);

			if (switchPageConditionRunner) {
				switchPageConditionRunner(page, itemsCount, () => {
					dispatch(setTablePage(page));
					dispatch(setTableDisplayLength(itemsCount));
					fetchMoreData(page, itemsCount, predicate, reverse, searchText);
				});
			} else {
				dispatch(setTablePage(page));
				dispatch(setTableDisplayLength(itemsCount));
				fetchMoreData(page, itemsCount, predicate, reverse, searchText);
			}
		};

		window.addEventListener('resize', resizeListener);
		window.addEventListener('popstate', popstateListener, false);

		return () => {
			window.removeEventListener('resize', resizeListener);
			window.removeEventListener('popstate', popstateListener, false);
		};
	}, []);

	useEffect(() => {
		updateCallback();
	}, [update]);

	// !----------------------- pagination ---------------------------------

	const pageCount = Math.ceil(fullTableDataLength / pageLength);

	const handleChangePageCallback = (event, value) => {
		setRowHeight(79.9); // костыль для того что бы сработал shouldUpdateScroll и веревел прокрутку в начало таблицы
		const {predicate, reverse, searchText} = parseUrlQuery(document.location);

		dispatch(setTablePage(value));
		updateURL(value, pageLength, predicate, reverse === 'true', searchText);
		fetchMoreData(value, pageLength, predicate, reverse === 'true', searchText);
	};

	const handleChangePage = (event, value) => {
		if (switchPageConditionRunner) {
			switchPageConditionRunner(value, pageLength, () => {
				handleChangePageCallback(event, value);
			});
		} else {
			handleChangePageCallback(event, value);
		}
	};

	const handleChangeLengthCallback = event => {
		const {predicate, reverse, searchText} = parseUrlQuery(document.location);
		dispatch(setTablePage(DEFAULT_PAGE));
		dispatch(setTableDisplayLength(Number(event.target.value)));
		updateURL(DEFAULT_PAGE, event.target.value, predicate, reverse === 'true', searchText);
		fetchMoreData(1, Number(event.target.value), predicate, reverse === 'true', searchText);
	};

	const handleChangeLength = event => {
		if (switchPageConditionRunner) {
			switchPageConditionRunner(1, Number(event.target.value), () => {
				handleChangeLengthCallback(event);
			});
		} else {
			handleChangeLengthCallback(event);
		}
	};

	// ! --------------------------------------- sorting --------------------------
	const handleSortColumn = sortColumn => {
		dispatch(setTableSort(sortColumn));
	};

	return (
		<div className={`pf-table`}>
			<div
				className="pf-table__body"
				style={{
					height: `calc(100vh - ${
						isMobileScreen ? MOBILE_HEADER_HEIGHT : HEADER_HEIGHT
					}px - ${tableData.length > 0 ? PAGINATION_BAR_HEIGHT : 0}px - ${
						modal === 'inviteToEcosystem' ? (isMobileScreen ? 110 : 80) : 0
					}px - ${
						hasLeadsViewRestriction
							? isUpgradePlanBarThreeRows
								? 92
								: isUpgradePlanBarTwoRows
								? 72
								: 42
							: 0
					}px)`,
				}}>
				<Table
					renderEmpty={() => emptyComponent}
					fillHeight={true} // ! высота таблицы равна высоте ее родительского контейнера
					headerHeight={tableHeaderHeight}
					rowHeight={rowHeight}
					hover={true}
					data={tableData}
					onRowClick={handleClickOnTableRow}
					ref={tableRef}
					shouldUpdateScroll={() => {
						return {x: 0, y: 0};
					}} //! false (сбросить положение прокрутки при изменении размера таблицы)
					wordWrap={false} //! перенос строк ("break-word")
					loading={isLoading}
					renderLoading={() => (
						<div className="rs-table-loader-wrapper">
							<PFLoader className={'rs-table-loading pf-table-loader'} />
						</div>
					)}
					rowClassName={rowData => rowClassName(rowData)}
					onSortColumn={handleSortColumn}>
					<Column width={90} align="center" verticalAlign={'middle'}>
						<HeaderCell style={{padding: 0}}>
							<div style={{lineHeight: '40px'}}>
								<Checkbox
									inline
									checked={checked}
									indeterminate={indeterminate}
									onChange={handleCheckAll}
								/>
							</div>
						</HeaderCell>
						<CheckCell
							dataKey={'token'} //! <-- можно использовать множественные ключи 'token/partnerToken'
							checkedKeys={checkedKeys}
							onChange={handleCheck}
						/>
					</Column>

					{tableColumns}
				</Table>
				{tableData.length > 0 && (
					<Stack direction="row" className={'pf-pagination'}>
						<FormControl
							variant="standard"
							sx={{minWidth: 120}}
							className={'pf-pagination-displayLength'}>
							<div className={'pf-pagination-displayLength-text'}>
								<span>{'Items '}</span>
								<span>{'per'}</span>
								{' page'}
							</div>
							<Select
								id="select"
								className={'pf-pagination-select'}
								value={pageLength}
								onChange={handleChangeLength}
								IconComponent={() => <div></div>}>
								<MenuItem value={5}>5</MenuItem>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={25}>25</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</FormControl>
						<div
							className={
								'pf-mobile-pagesCount'
							}>{`${tablePage} of ${pageCount}`}</div>
						<Pagination
							count={pageCount}
							page={tablePage}
							siblingCount={1}
							onChange={handleChangePage}
							defaultPage={1}
							shape="rounded"
							className={'pf-pagination-pages'}
						/>
					</Stack>
				)}
				{modal && (
					<PFSideModal isLoading={isLoading} modalTogler={modalToggle} id={rowData.token}>
						{modal === 'leads' && (
							<LeadsModal hasCurrentCountRestriction={hasCurrentCountRestriction} />
						)}
						{modal === 'current' && (
							<CurrentModal updateDataForUseOnModal={updateDataForUseOnModal} />
						)}
						{modal === 'consumer' && (
							<ConsumersModal updateDataForUseOnModal={updateDataForUseOnModal} />
						)}
						{modal === 'companies' && (
							<CompaniesModal updateDataForUseOnModal={updateDataForUseOnModal} />
						)}
						{modal === 'companyProfile' && (
							<CompanyProfileModal
								updateDataForUseOnModal={updateDataForUseOnModal}
								tableData={tableData}
							/>
						)}
						{modal === 'api' && (
							<ApiModal updateDataForUseOnModal={updateDataForUseOnModal} />
						)}
						{(modal === 'ecosystemAdvisors' || modal === 'inviteToEcosystem') && (
							<EcosystemModal />
						)}
						{modal === 'referredToMe' && <ReferredToMeModal />}
					</PFSideModal>
				)}
			</div>
		</div>
	);
};

export default PFTable;
