import { useEffect, useRef } from 'react';

const PFSafeHtmlRenderer = ({ htmlContent, className }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.innerHTML = htmlContent;
    }
  }, [htmlContent]);

  return <div ref={contentRef} className={className}></div>;
};

export default PFSafeHtmlRenderer;
