import React, {useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {Amplitude, getClassNamesWithVariant} from '../../../utils';
import {useSignOut} from '../../hooks/auth';
import {
	InviteAdvisorModalActions,
	setIsOpenReferPopup,
	setOpenDeepLinkPopup,
	setSideModal,
} from '../../../store/actions';
import PFAvatar from '../PF-Avatar';
import PFNavigation from '../PF-Navigation';
import PFLogoLink from '../PF-LogoLink';
import PFSearch from '../PF-Search';
import PORTAL_PATHS from '../../../const/url-paths/portal';
import urlPaths from '../../../api/url-paths';
import {setTableReset} from '../../../store/actions';
import iconProfile from '../../../assets/header/profile.svg';
import iconLogout from '../../../assets/header/logout.svg';
import manageSubscriptionIcon from '../../../assets/header/manage-subscription-icon.svg';
import {accountSettings, inviteNewAdvisorIcon, profileLink, referBusiness, proEmailSignature, frenchVillageCommunity} from '../../../assets';
// import iconExport from '../../../assets/header/export.svg';
// import iconImport from '../../../assets/header/import.svg';
import iconHelp from '../../../assets/icon-help.svg';
import banner from '../../../assets/icon-banner.svg';
import eduHab from '../../../assets/icon-edu-hub.svg';
import PFDropdownItem from '../PF-DropdownItem';
import {GetIsSideModalOpen} from '../../../store/selectors/UISetings';
import Notifications from '../../../components/Notifications/Notifications';
import {useAppDispatch, useAppSelector} from '../../../store';
import {SubscriptionSelectors, myProfileSelectors} from '../../../store/selectors';
import {selectIsShowSearch} from '../../../store/selectors/table';
import classNames from 'classnames';
import './style.scss';
import {AdvisorsRole} from '../../../types/enums';
import {UpgradePlanBadge, UpgradePlanButton} from 'components/ui/upgrade-plan';
import PFLink from '../PF-Link';
import {PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK} from 'const';
import { ReactSVG } from 'react-svg';
import PFButton from '../PF-Button';

const PFHeader = ({variant, className, showNavigation, hideUserDetails, isDeepLink}) => {
	const auth = useAppSelector(state => state.general.auth);
	const profile = useAppSelector(myProfileSelectors.selectMyProfileData);
	const role = profile?.roles; // ! new
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const findReducerName = window.location.pathname.split('/').reverse()[0]; // reducer name must match pathname
	const reducerName = findReducerName === 'company-profile' ? 'companies' : findReducerName;
	const reducerPropertyName =
		findReducerName === 'company-profile' ? 'companyUsers' : findReducerName;
	const tableData = useAppSelector(state =>
		state[reducerName] ? state[reducerName][reducerPropertyName] : [],
	);
	const isSideModalOpen = GetIsSideModalOpen();
	const selectedTableRow = useAppSelector(state => state.table.selectedTableRow);
	const isShowSearch = useAppSelector(selectIsShowSearch);
	const hasSubscription = useAppSelector(SubscriptionSelectors.selectHasSubscription);
	const {showConfirm, ConfirmModal} = useSignOut(navigate);
	const goToProfile = () => navigate(PORTAL_PATHS.OTHER_PATHS.MY_PROFILE);
	const goToEditLoginSettings = () => navigate(PORTAL_PATHS.OTHER_PATHS.LOGIN_SETTINGS_EDIT);
	const showFreshChatWidget = () => {
		Amplitude.track('Help/FAQ');

		window.fcWidget.open();
	};
	const goToAccountSettings = () => navigate(PORTAL_PATHS.OTHER_PATHS.ACCOUNT_SETTINGS);
	const goToManageSubscription = () => {
		Amplitude.track('Opened_Upgrade', {
			source: 'Navigation',
		});

		navigate(PORTAL_PATHS.OTHER_PATHS.MANAGE_SUBSCRIPTION);
	};

	const profileImg = profile?.advisor?.profileImage;

	const dropdownMenu = role => {
		if (role.includes(AdvisorsRole.SuperAdmin)) {
			// ! new
			return (
				<Dropdown.Menu
					popperConfig={{
						modifiers: [
							{
								name: 'offset',
								options: {
									offset: [3, 19],
								},
							},
						],
					}}
					className="pf-header__dropdown-menu">
					<div className={'pf-superAdminHeader__dropdown-menu'}>
						<Dropdown.Item onClick={goToEditLoginSettings}>
							Edit Login Settings
						</Dropdown.Item>
						<Dropdown.Item onClick={showConfirm}>
							<PFDropdownItem icon={iconLogout}>Logout</PFDropdownItem>
						</Dropdown.Item>
					</div>
				</Dropdown.Menu>
			);
		} else {
			return (
				<Dropdown.Menu
					popperConfig={{
						modifiers: [
							{
								name: 'offset',
								options: {
									offset: [3, 19],
								},
							},
						],
					}}
					className="pf-header__dropdown-menu">
					<Dropdown.Header className="dropdown-user-section">
						{profile?.firstName + ' ' + profile?.lastName}
						<ReactSVG onClick={showConfirm} className="logout-icon" src={iconLogout} />
					</Dropdown.Header>
					{/* <div className="banner__hr" /> */}
					{(role.includes(AdvisorsRole.Advisor) ||
						role.includes(AdvisorsRole.AccountAdministrator) ||
						role.includes(AdvisorsRole.Administrator)) && (
						<>
							{hasSubscription ? (
								<Dropdown.Item onClick={goToManageSubscription}>
									<PFDropdownItem icon={manageSubscriptionIcon}>
										Manage Subscription
									</PFDropdownItem>
								</Dropdown.Item>
							) : (
								<UpgradePlanButton onClick={goToManageSubscription} />
							)}

							{/* <div className="banner__hr" /> */}
						</>
					)}
					<Dropdown.Item onClick={goToProfile}>
						<PFDropdownItem icon={iconProfile}>My Profile</PFDropdownItem>
					</Dropdown.Item>
					{isDeepLink && (
						<Dropdown.Item
							onClick={() =>
								dispatch(
									setOpenDeepLinkPopup({
										advisorToken: profile.advisor.token,
										isMyLink: true,
									}),
								)
							}>
							<PFDropdownItem icon={profileLink} className="shareProfileLink">
								Share My Profile Link
							</PFDropdownItem>
						</Dropdown.Item>
					)}
					<Dropdown.Item
						onClick={() => dispatch(InviteAdvisorModalActions.setOpen('Navigation'))}>
						<PFDropdownItem icon={inviteNewAdvisorIcon}>
							Invite New Advisors
						</PFDropdownItem>
					</Dropdown.Item>
					<Dropdown.Item onClick={() => dispatch(setIsOpenReferPopup('Menu'))}>
						<PFDropdownItem icon={referBusiness}>Refer Business</PFDropdownItem>
					</Dropdown.Item>
					<Dropdown.Item onClick={goToAccountSettings}>
						<PFDropdownItem icon={accountSettings}>Account Settings</PFDropdownItem>
					</Dropdown.Item>
					{/* <Dropdown.Item onClick={showConfirm}>
						<PFDropdownItem icon={iconLogout}>Logout</PFDropdownItem>
					</Dropdown.Item> */}

					<Dropdown.Item onClick={showFreshChatWidget}>
						<PFDropdownItem icon={iconHelp}>Help/FAQ</PFDropdownItem>
					</Dropdown.Item>
					<PFButton
						className='pro-email-signature'
						variant="primary"
						prefixIcon={<ReactSVG wrapper='span' src={proEmailSignature} />}
						onClick={() => window.open('https://hub.mysignature.io/login', '_blank')}
						type="button">
						Pro Email Signature
					</PFButton>
					<PFButton
						className='french-village-community'
						variant="primary"
						prefixIcon={<ReactSVG wrapper='span' src={frenchVillageCommunity} />}
						onClick={() => window.open('https://village-finance.mn.co/', '_blank')}
						type="button">
						French Village Community
					</PFButton>
					{/* <div className="banner">
						<div
							className="banner-main-wrapper"
							style={{backgroundImage: `url(${banner})`}}>
							<div className="banner-main">
								<h2 className={'banner-title'}>Coming Soon:</h2>
								<PFDropdownItem icon={eduHab} className={'eduHab'}>
									Edu Hub
								</PFDropdownItem>
							</div>
						</div>
					</div> */}
					{/* Временно убрали, пока не реализован функционал кнопок*/}
					{/*<Dropdown.Item disabled={true}>*/}
					{/*	<PFDropdownItem icon={iconImport} className={'disabled'}>*/}
					{/*		Import Contacts*/}
					{/*	</PFDropdownItem>*/}
					{/*</Dropdown.Item>*/}
					{/*<Dropdown.Item disabled={true}>*/}
					{/*	<PFDropdownItem icon={iconExport} className={'disabled'}>*/}
					{/*		Export Contacts*/}
					{/*	</PFDropdownItem>*/}
					{/*</Dropdown.Item>*/}
					<div className="banner__hr" />
					<div className={'dropdown-footer'}>
						<PFDropdownItem>
							<PFLink href={TERMS_OF_USE_LINK} target="_blank">
								Terms of Use
							</PFLink>
						</PFDropdownItem>
						<PFDropdownItem>
							<PFLink href={PRIVACY_POLICY_LINK} target="_blank">
								Privacy Policy
							</PFLink>
						</PFDropdownItem>
					</div>
				</Dropdown.Menu>
			);
		}
	};

	const handleIsActiveRow = id => {
		const foundActiveRow = tableData.find(item => item.token === id);
		if (foundActiveRow) {
			if (foundActiveRow.isActive) {
				foundActiveRow.isActive = false;
			} else {
				foundActiveRow.isActive = true;
			}
		}
	};

	const onClickAvatarButton = () => {
		if (isSideModalOpen) {
			handleIsActiveRow(selectedTableRow.token);
			dispatch(setSideModal(false));
		}
	};

	const [closeSearch, setCloseSearch] = useState(false);

	return (
		<div
			className={getClassNamesWithVariant(
				'pf-header',
				variant,
				classNames(className, {showSearch: isShowSearch}),
			)}>
			<div className="pf-header__container">
				{showNavigation ? (
					<>
						<PFLogoLink
							to={PORTAL_PATHS.PORTAL_BASE_URL}
							settingClasses="d-none d-xl-block"
						/>

						<PFNavigation
							isHeaderNavigation
							onNavItemClick={() => {
								dispatch(setSideModal(false));
								dispatch(setTableReset());
								setCloseSearch(!closeSearch);
							}}
						/>
					</>
				) : (
					<PFLogoLink to={PORTAL_PATHS.PORTAL_BASE_URL} />
				)}
			</div>
			<div className="pf-header__user">
				{auth && !hideUserDetails && (
					<>
						{(role.includes(AdvisorsRole.Advisor) ||
							role.includes(AdvisorsRole.AccountAdministrator) ||
							role.includes(AdvisorsRole.Administrator)) &&
							!hasSubscription && (
								<div className="d-none d-xl-block">
									<UpgradePlanBadge onClick={goToManageSubscription} />
								</div>
							)}
						<PFSearch closeSearch={closeSearch} />
						{role && !role.includes(AdvisorsRole.SuperAdmin) && (
							<Notifications className="pf-header__notification" />
						)}
						{/* This renders the dropdown after the user avatar is clicked */}
						<Dropdown
							onClick={onClickAvatarButton}
							className="pf-header__dropdown d-none d-xl-block">
							<Dropdown.Toggle
								variant=""
								className="pf-header__dropdown-toggle p-0"
								id="dropdown-basic">
								<PFAvatar
									src={profileImg && `${urlPaths.BASE_IMAGES_URL}${profileImg}`}
									size={40}
								/>
							</Dropdown.Toggle>
							{role && dropdownMenu(role)}
						</Dropdown>
						<ConfirmModal />
					</>
				)}
			</div>
		</div>
	);
};

export default PFHeader;
