import React from 'react';
import {ReactSVG} from 'react-svg';
import {Link} from 'react-router-dom';
import {PFButton} from '../index';
import {SIDEBAR_MENUS} from '../../../const';
import {setSidebar, setSideModal} from '../../../store/actions';
import mobileMenuIcon from '../../../assets/icon-menu-mobile.svg';
import classnames from 'classnames';
import {buildURLParams, isSuitableRole} from '../../../utils';
import {
	GetCurrentPage,
	GetIsSidebarOpen,
	GetIsSideModalOpen,
} from '../../../store/selectors/UISetings';
import {useAppDispatch, useAppSelector} from '../../../store';
import {myProfileSelectors} from '../../../store/selectors';

import './style.scss';

const PFNavigation = ({isHeaderNavigation, onNavItemClick}) => {
	const dispatch = useAppDispatch();
	const profile = useAppSelector(myProfileSelectors.selectMyProfileData);
	const findReducerName = window.location.pathname.split('/').reverse()[0]; // reducer name must match pathname
	const reducerName = findReducerName === 'company-profile' ? 'companies' : findReducerName;
	const reducerPropertyName =
		findReducerName === 'company-profile' ? 'companyUsers' : findReducerName;
	const tableData = useAppSelector(state =>
		state[reducerName] ? state[reducerName][reducerPropertyName] : [],
	);
	const isSidebarOpen = GetIsSidebarOpen();
	const currentPage = GetCurrentPage();
	const isSideModalOpen = GetIsSideModalOpen();
	const selectedTableRow = useAppSelector(state => state.table.selectedTableRow);

	const handleIsActiveRow = id => {
		const foundActiveRow = tableData.find(item => item.token === id);
		if (foundActiveRow) {
			if (foundActiveRow.isActive) {
				foundActiveRow.isActive = false;
			} else {
				foundActiveRow.isActive = true;
			}
		}
	};

	const toggleSidebar = () => {
		dispatch(setSidebar(!isSidebarOpen));
		if (isSideModalOpen) {
			handleIsActiveRow(selectedTableRow.token);
			dispatch(setSideModal(false));
		}
	};
	const menuIcon = <ReactSVG src={mobileMenuIcon} />;

	return (
		<>
			{profile && (
				<nav
					className={classnames('pf-navigation', {
						sidebar__nav: !isHeaderNavigation,
					})}>
					<ul className="pf-navigation__list">
						{SIDEBAR_MENUS.filter(item =>
							isSuitableRole(item.role, profile?.roles),
						).map(menu => {
							const key = `nav-${menu.label}`;
							return (
								<li
									className={classnames(
										`pf-navigation__item ${profile?.roles[0]}`,
										{
											active: menu.label === currentPage,
											'd-none d-xl-flex':
												menu.label !== currentPage && isHeaderNavigation,
											sidebar__item: !isHeaderNavigation,
										},
									)}
									id={key}
									key={key}>
									{isHeaderNavigation && (
										<div className="menu d-xl-none">
											<PFButton
												className="menu-btn"
												prefixIcon={menuIcon}
												onClick={toggleSidebar}
												variant="plain"
											/>
										</div>
									)}
									{onNavItemClick ? (
										<Link
											onClick={onNavItemClick}
											to={`${menu.path}?${buildURLParams(
												1,
												25,
												'',
												false,
												'',
											)}`}>
											{menu.label}
										</Link>
									) : (
										<Link to={menu.path}>{menu.label}</Link>
									)}
								</li>
							);
						})}
						{!SIDEBAR_MENUS.find(menu => menu.label === currentPage) &&
							isHeaderNavigation && (
								<li className="pf-navigation__item single d-xl-none">
									<div className="menu">
										<PFButton
											className="menu-btn"
											prefixIcon={menuIcon}
											onClick={toggleSidebar}
											variant="plain"
										/>
									</div>
								</li>
							)}
					</ul>
				</nav>
			)}
		</>
	);
};

export default PFNavigation;
