import {setMyProfile} from '../../../store/actions';
import * as advisorService from '../../../api/services/advisor';
import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import PFPopup from '../../components/PF-Popup';
import PFInput from '../../components/PF-Input';
import {Amplitude, generateValidator, hints, validations} from '../../../utils';
import PFButton from '../../components/PF-Button';
import {HiEye, HiEyeOff} from 'react-icons/hi';
import {ToastDispatcher} from 'utils';
import {useAppDispatch} from '../../../store';
import {fetchSubscription} from 'store/reducers/SubscriptionReducer';

export const useProfile = () => {
	const dispatch = useAppDispatch();

	const fetch = useCallback(async () => {
		try {
			const res = await advisorService.getAdvisor();
			dispatch(setMyProfile(res.data));

			Amplitude.identifyUser(res.data);

			await dispatch(fetchSubscription());
		} catch (e) {
			throw e;
		}
	}, [dispatch]);

	return fetch;
};

export const useUpdateProfile = () => {
	const fetchProfile = useProfile();
	return async profile => {
		try {
			const res = await advisorService.updateAdvisor(profile);
			ToastDispatcher.success('Advisor profile updated');
			// TODO remove when backend fixes the speed of the API calls
			// We have this timeout because without it, after pressing submit on ProfileEdit, it redirects us to MyProfile without showing the updated profile data
			setTimeout(async () => {
				await fetchProfile();
			}, 400);
			return res;
		} catch (e) {
			throw e;
		}
	};
};

export const useUpdateUserProfile = () => {
	return async profile => {
		try {
			const res = await advisorService.updateAdvisor(profile);
			ToastDispatcher.success('Advisor profile updated');
			return res;
		} catch (e) {
			throw e;
		}
	};
};

export const useCertificates = () => {
	const body = {
		// take: 20,
		SearchText: '',
		skip: 0,
	};
	return async (payload = body) => {
		try {
			const {data} = await advisorService.getCertificates(payload);
			return data;
		} catch (e) {
			return [];
		}
	};
};

export const useLocations = () => {
	const body = {
		take: 10,
		Query: '',
		skip: 0,
	};
	return async (payload = body) => {
		try {
			const {data} = await advisorService.getLocations(payload);
			return data;
		} catch (e) {
			return [];
		}
	};
};

export const useHashtags = () => {
	const body = {
		// take: 10,
		SearchText: '',
		skip: 0,
		type: 1,
	};
	return async (payload = body) => {
		try {
			const {data} = await advisorService.getHashtags(payload);
			return data;
		} catch (e) {
			return [];
		}
	};
};

export const useAddNewAdvisor = navigate => {
	return async profile => {
		try {
			const res = await advisorService.addNewAdvisor(profile);
			return res;
		} catch (e) {
			ToastDispatcher.error(`${e?.response?.data?.message}`);
			throw e;
		}
	};
};

export const useChangePasswordPopup = () => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showChangePasswordConfirm = () => setShowConfirmation(true);
	const cancelChangePasswordConfirm = () => setShowConfirmation(false);

	return {
		ConfirmChangePasswordModal: ({onSubmit, className}) => {
			const formOptions = {
				mode: 'onChange',
			};
			const formProps = useForm(formOptions);
			const {formState, handleSubmit, watch, trigger} = formProps;

			const [isSubmitting, setIsSubmitting] = useState(false);
			const [isVisiblePsw, setIsVisiblePsw] = useState(false);
			const [isVisibleConfirmPsw, setIsVisibleConfirmPsw] = useState(false);
			const [isVisibleOldPsw, setIsVisibleOldPsw] = useState(false);

			const passwordIcon = isVisiblePsw ? (
				<HiEyeOff onClick={() => togglePasswordVisibility('password')} size={15} />
			) : (
				<HiEye onClick={() => togglePasswordVisibility('password')} size={15} />
			);

			const confirmPasswordIcon = isVisibleConfirmPsw ? (
				<HiEyeOff onClick={() => togglePasswordVisibility('confirmPassword')} size={15} />
			) : (
				<HiEye onClick={() => togglePasswordVisibility('confirmPassword')} size={15} />
			);

			const oldPasswordIcon = isVisibleOldPsw ? (
				<HiEyeOff onClick={() => togglePasswordVisibility('oldPassword')} size={15} />
			) : (
				<HiEye onClick={() => togglePasswordVisibility('oldPassword')} size={15} />
			);

			const togglePasswordVisibility = type => {
				if (type === 'password') {
					setIsVisiblePsw(visible => !visible);
				} else if (type === 'confirmPassword') {
					setIsVisibleConfirmPsw(visible => !visible);
				} else if (type === 'oldPassword') {
					setIsVisibleOldPsw(visible => !visible);
				}
			};

			useEffect(() => {
				const subscription = watch(async (data, {name}) => {
					if (name === 'password') {
						await trigger('confirmPassword');
					}
				});
				return () => subscription.unsubscribe();
			}, [watch, trigger]);

			const fullWidthClass = 'col-12 mb-4';

			const submitForm = async valueObj => {
				const body = {
					password: valueObj.password,
					oldPassword: valueObj.oldPassword,
				};

				try {
					setIsSubmitting(true);
					await onSubmit?.(body);
					cancelChangePasswordConfirm();
				} catch (e) {
					throw e;
				} finally {
					setIsSubmitting(false);
				}
			};

			return (
				<>
					<PFPopup
						isShow={showConfirmation}
						handleClose={cancelChangePasswordConfirm}
						isShowFooter={false}
						submitBtnText="Save"
						primaryVariant="primary"
						isCloseButton={true}
						isShowCancelBtn={false}
						title="Change Password"
						onSubmit={() => false}
						className={{root: className}}>
						<form onSubmit={handleSubmit(submitForm)} className={`pf-IsActive__form`}>
							<div className={`${fullWidthClass} oldPassword`}>
								<PFInput
									{...formProps.register('oldPassword', {
										required: true,
									})}
									showAsterisk={false}
									label={'Current Password'}
									placeholder={'Current password'}
									autoComplete={'new-password'}
									icon={oldPasswordIcon}
									type={isVisibleOldPsw ? 'text' : 'password'}
									hint={''}
									defaultValue={''}
									error={
										formState.errors.oldPassword?.message &&
										formState.errors.oldPassword?.message
									}
								/>
							</div>
							<div className={`${fullWidthClass} password`}>
								<PFInput
									{...formProps.register('password', {
										validate: generateValidator(true, validations.password),
										required: true,
									})}
									showAsterisk={false}
									label={'New Password'}
									placeholder={'Password'}
									autoComplete={'new-password'}
									icon={passwordIcon}
									type={isVisiblePsw ? 'text' : 'password'}
									hint={hints.password.index}
									defaultValue={''}
									error={
										formState.errors.password?.message &&
										formState.errors.password?.message
									}
								/>
							</div>
							<div className={`${fullWidthClass} confirmPassword`}>
								<PFInput
									{...formProps.register('confirmPassword', {
										validate: value =>
											value === formProps.getValues('password') ||
											'The passwords do not match!',
									})}
									showAsterisk={false}
									label={'Confirm New Password'}
									placeholder={'Confirm password'}
									autoComplete={'new-password'}
									icon={confirmPasswordIcon}
									type={isVisibleConfirmPsw ? 'text' : 'password'}
									defaultValue={''}
									error={
										formState.errors.confirmPassword?.message &&
										formState.errors.confirmPassword?.message
									}
								/>
							</div>
							<div className="d-flex justify-content-center">
								<div className="d-flex w-100 m-auto pf-modal__footer-inner">
									<PFButton
										disabled={!formState.isValid || isSubmitting}
										onClick={() => false}
										className="pf-profileEdit__form-button pf-profileEdit__form-button--submit pf-edit-email-submit"
										type="submit">
										Save
									</PFButton>
									<PFButton
										onClick={cancelChangePasswordConfirm}
										variant="secondary"
										type={'button'}>
										Cancel
									</PFButton>
								</div>
							</div>
						</form>
					</PFPopup>
				</>
			);
		},
		showConfirmation,
		showChangePasswordConfirm,
		cancelChangePasswordConfirm,
	};
};

// Cache options in memory
let cachedOptions = null;

export const loadLocationTokenOptions = async (query, loadedOptions) => {
  // Load from localStorage only once
  if (!cachedOptions) {
    const storedOptions = localStorage.getItem("locationTokenOptions");
    cachedOptions = storedOptions ? JSON.parse(storedOptions) : [];
  }

  // Filter the cached options based on the query
  const filteredOptions = cachedOptions.filter((option) =>
    option.name.toLowerCase().includes(query?.toLowerCase())
  );

  // Determine how many options have been loaded so far and whether there are more
  const currentCount = filteredOptions.length;
  const hasMore = currentCount > loadedOptions.length;

  // Return the filtered options and the hasMore flag
  return {
    options: filteredOptions.slice(loadedOptions.length, loadedOptions.length + 10), // Paginate more efficiently
    hasMore,
  };
};
  
