import { REGEX_FOR_YOUTUBE_LINK } from '../../const/forms';

const generatorAtLeastOneNumber = hint => v => /(?=.*[0-9])/.test(v) || hint;
const generatorAtLeastOneLatinLetter = hint => v => /(?=.*[A-Za-z])/.test(v) || hint;
const generatorWithoutSpaces = hint => v => /^\S+$/.test(v) || hint;
const generatorWithoutSpacesAroundTheEdges = hint => v => /^[^\s]+(\s+[^\s]+)*$/.test(v) || hint;
const generatorAtLeastOneSpecialCharacter = hint => v => /(?=.*[\W_])/.test(v) || hint; // ! before 07.07.2022 /(?=.*[@#$%^&*!_-])/
const generatorAtLeastCharacters = hint => v => /(?=^.{8,}$)/.test(v) || hint; // !  or v.length >= 8
// const generatorMustNotContainSpecialCharacters = hint => v => !/[^\w!@^_*&%$#-]+/.test(v) || hint;
// email
const generatorEmailShouldLookLike = hint => v =>
	/^[a-zA-Z0-9_~.+-]+@[a-zA-Z0-9-]+\.[A-Za-z]+$/.test(v) || hint;
// website
const generatorWebsiteShouldLookLike = hint => v =>
	/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(v) || hint;
// fields
const generatorMaxLength = (hint, length) => v =>
	(!!v && v.length <= length) || (typeof hint === 'function' ? hint(length) : hint);
const generatorMinLength = (hint, length) => v => (!!v && v.length >= length) || hint;
const generatorEmptyValue = hint => v => !!v.trim() || hint;
const generatorYouTubeLink = hint => v => REGEX_FOR_YOUTUBE_LINK.test(v) || hint;
const generatorPolicyNumber = hint => v => /^[a-zA-Z0-9]{1,15}$/.test(v) || hint;

export const hints = {
	password: {
		index: 'Password should consist of at least 8 characters and be the mix of digits, letters and special symbols.',
		withoutSpaces: 'Password can not contain spaces.',
		tooShort: 'Password is too short.',
		// containsIncorrectSymbol: 'Password can contain only following special symbols: !@#$%^&*_-',
		containDigits: 'Add at least one digit to your password.',
		containLatinLetters: 'Add at least one Latin letter to your password.',
		containSpecialSymbols: 'Add at least one special symbol.',
		required: 'You have not filled this text box.',
	},
	email: {
		index: 'The email should look like "email@domain.com"',
		required: 'Email is required',
	},
	textFields: {
		withoutSpaces: 'Cannot contain leading or trailing spaces',
		withoutSpacesAroundTheEdges:
			'There must be no spaces at the beginning and the end of the line',
		containsIncorrectSymbol:
			'Text field can contain only following special symbols: !@#$%^&*_-',
		maxLength: count => `Max length of ${count} characters`,
		minLength: '',
		required: 'This field is required',
		youTubeLink:
			'The link should look like "https://www.youtube.com/watch?v=xxxxxxxxxxx" or "https://youtu.be/xxxxxxxxxxx"',
		policyNumber:
			'Policy number should contain numbers and letters only and should be 15 characters max',
		website:
			'The website should look like "https://www.example.com"',
	},
};

export const validations = {
	password: {
		atLeastOneNumber: generatorAtLeastOneNumber(hints.password.containDigits),
		atLeastOneLatinLetter: generatorAtLeastOneLatinLetter(hints.password.containLatinLetters),
		withoutSpaces: generatorWithoutSpaces(hints.password.withoutSpaces),
		atLeastOneSpecialCharacter: generatorAtLeastOneSpecialCharacter(
			hints.password.containSpecialSymbols,
		),
		atLeastCharacters: generatorAtLeastCharacters(hints.password.tooShort),
	},
	email: {
		emailShouldLookLike: generatorEmailShouldLookLike(hints.email.index),
		maxLength: generatorMaxLength(hints.textFields.maxLength, 85),
	},
	code: {
		minLength: generatorMinLength('Min length of 5 characters', 5),
		maxLength: generatorMaxLength('', 5),
	},
	textFields: {
		firstName: {
			withoutSpaces: generatorWithoutSpacesAroundTheEdges(
				hints.textFields.withoutSpacesAroundTheEdges,
			),
			minLength: generatorMinLength(hints.textFields.minLength, 1),
			maxLength: generatorMaxLength(hints.textFields.maxLength, 35),
		},
		lastName: {
			withoutSpaces: generatorWithoutSpacesAroundTheEdges(
				hints.textFields.withoutSpacesAroundTheEdges,
			),
			minLength: generatorMinLength(hints.textFields.minLength, 1),
			maxLength: generatorMaxLength(hints.textFields.maxLength, 50),
		},
		description: {
			maxLength: generatorMaxLength('', 490),
		},
		referBusinessDescription: {
			maxLength: generatorMaxLength('', 590),
		},
		contactLink: {
			withoutSpacesAroundTheEdges: generatorWithoutSpacesAroundTheEdges(
				hints.textFields.withoutSpacesAroundTheEdges,
			),
			maxLength: generatorMaxLength(hints.textFields.maxLength, 256),
		},
		apiInstanceName: {
			withoutSpacesAroundTheEdges: generatorWithoutSpacesAroundTheEdges(
				hints.textFields.withoutSpacesAroundTheEdges,
			),
			maxLength: generatorMaxLength(hints.textFields.maxLength, 60),
		},
		apiInstanceNote: {
			withoutSpacesAroundTheEdges: generatorWithoutSpacesAroundTheEdges(
				hints.textFields.withoutSpacesAroundTheEdges,
			),
			maxLength: generatorMaxLength(hints.textFields.maxLength, 115),
		},
		companyName: {
			withoutSpacesAroundTheEdges: generatorWithoutSpacesAroundTheEdges(
				hints.textFields.withoutSpacesAroundTheEdges,
			),
			maxLength: generatorMaxLength(hints.textFields.maxLength, 60),
		},
		streetAddress: {
			withoutSpacesAroundTheEdges: generatorWithoutSpacesAroundTheEdges(
				hints.textFields.withoutSpacesAroundTheEdges,
			),
			maxLength: generatorMaxLength(hints.textFields.maxLength, 40),
		},
		postalCode: {
			withoutSpaces: generatorWithoutSpaces(hints.textFields.withoutSpaces),
			maxLength: generatorMaxLength(hints.textFields.maxLength, 10),
		},
		emailsList: {
			maxLength: generatorMaxLength(hints.textFields.maxLength, 85),
			emptyValue: generatorEmptyValue(''),
		},
		locationToken: {
			emptyValue: generatorEmptyValue(hints.textFields.required),
		},
		videoPresentationLink: {
			maxLength: generatorMaxLength(hints.textFields.maxLength, 256),
			youTubeLinkShouldLookLike: generatorYouTubeLink(hints.textFields.youTubeLink),
		},
		policyNumber: {
			withoutSpaces: generatorWithoutSpaces('Cannot contain spaces'),
			// emptyValue: generatorEmptyValue(hints.textFields.required),
			maxLength: generatorMaxLength(hints.textFields.policyNumber, 15),
			policyNumberShouldLookLike: generatorPolicyNumber(hints.textFields.policyNumber),
		},
		webSite: {
			websiteShouldLookLike: generatorWebsiteShouldLookLike(hints.textFields.website),
			maxLength: generatorMaxLength(hints.textFields.maxLength, 85),
		},
		opportunitiesNote: {
			maxLength: generatorMaxLength('', 92),
		},
		todoTitle: {
			maxLength: generatorMaxLength('', 100),
		},
		todoText: {
			maxLength: generatorMaxLength('', 500),
		},
		leadsRejectReason: {
			maxLength: generatorMaxLength('', 90),
		},
		customClientTags: {
			maxLength: generatorMaxLength('', 20),
		},
	},
};

export const generateValidator = (required, validators) => {
	return value => {
		if (typeof required !== 'string' && !required && (value === '' || value === null)) {
			return true;
		} else if (typeof required !== 'string' && !!required && (value === '' || value === null)) {
			return false;
		} else if (typeof required === 'string' && !!required && (value === '' || value === null)) {
			return required;
		}

		const keys = Object.values(validators);
		for (let i = 0; i < keys.length; i++) {
			const result = keys[i](value);
			if (result !== true) {
				return result;
			}
		}
		return true;
	};
};
