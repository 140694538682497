import React from 'react';
import {ReactSVG} from 'react-svg';

import upgradePlanArrowIcon from 'assets/header/upgrade-plan-arrow-icon.svg';
import upgradePlanButtonIcon from 'assets/header/upgrade-plan-button-icon.svg';
import classNames from 'classnames';

import banner from '../../../assets/icon-banner.svg';

import styles from './button.styles.module.scss';

export const UpgradePlanButton: React.FC<{
	onClick: () => void;
}> = ({onClick}) => {
	return (
		<div className="upgrade-action-wrapper" style={{backgroundImage: `url(${banner})`}}>
			<div
				className={classNames('upgrade-action-button', styles.container)}
				onClick={onClick}
				aria-hidden
				role="button"
				tabIndex={0}>
				<ReactSVG wrapper="div" src={upgradePlanButtonIcon} />
				<div className={styles.labels}>
					<div className={styles.title}>Upgrade</div>
					<div className={styles.subtitle}>& get much more</div>
				</div>
				<ReactSVG wrapper="div" src={upgradePlanArrowIcon} />
			</div>
		</div>
	);
};
