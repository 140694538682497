import {ReferringToBusinessesType} from '../../../types/enums';
import httpClient from '../../httpClient/httpClient';
import URL from '../../url-paths';

type UnregisteredUsersType = {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
};

export type SetReferBusinessesRequestType = {
	userTokens: string[] | null;
	unregisteredUsers: UnregisteredUsersType[] | null;
	type: ReferringToBusinessesType;
	description: string;
	advisorTokens: string[];
	businessCompany: null;
};

export const searchReferringToBusinesses = params =>
	httpClient.get(`${URL.BASE_URL}/${URL.REFERRING_TO_BUSINESSES.INDEX}/search`, {params});

export const setReferBusinesses = (body: SetReferBusinessesRequestType) =>
	httpClient.post(`${URL.BASE_URL}/${URL.REFERRING_TO_BUSINESSES.INDEX}`, body);

export const dismissReferral = (body: {tokens: string[]; rejectReason: string}) =>
	httpClient.delete(
		`${URL.BASE_URL}/${URL.REFERRING_TO_BUSINESSES.INDEX}?rejectReason=${body.rejectReason}`,
		{data: body.tokens},
	);

export const acceptReferral = (body: string[]) =>
	httpClient.post(`${URL.BASE_URL}/${URL.REFERRING_TO_BUSINESSES.INDEX}/accept`, body);

export const scheduleMeeting = (body: string[]) =>
	httpClient.post(`${URL.BASE_URL}/${URL.REFERRING_TO_BUSINESSES.INDEX}/scheduleMeeting`, body);

export const resetLastViewedReferItems = (body: string[]) =>
	httpClient.put(`${URL.BASE_URL}/${URL.REFERRING_TO_BUSINESSES.INDEX}/resetLastViewed`, body);

export const restoreReferBusinessesItems = (body: string[]) =>
	httpClient.post(`${URL.BASE_URL}/${URL.REFERRING_TO_BUSINESSES.INDEX}/restore`, body);
