import React, {useState} from 'react';
import {ReactSVG} from 'react-svg';

import {referBusinessesService} from 'api/services';
import {accept_button_icon, iconBlock, scheduleMeetingIcon} from 'assets';
import DismissReferPopup from 'pages/private/ReferredToMe/components/DismissReferPopup/DismissReferPopup';
import PFButton from 'shared/components/PF-Button';
import PFSafeHtmlRenderer from 'shared/components/PF-SafeHtmlRenderer';
import useBooleanState from 'shared/hooks/utility/useBooleanState';
import {dismissReferItems, removeCheckedItems, setSideModal} from 'store/actions';
import {Amplitude, mapQueryParams, parseUrlQuery, ToastDispatcher} from 'utils';

import {useReferralsData} from '../../../shared/hooks';
import {useAppDispatch} from '../../../store';
import {REFERRING_TO_BUSINESSES_OPTIONS_TYPES} from '../../ReferBusinessPopup/components/constants';
import {SnackActionButton} from '../../Snackbar';

import s from './style.module.scss';

const AdditionalReferredToMeInfo = ({
	userToken,
	referredType,
	description,
	userFirstName,
	userLastName,
}) => {
	const dispatch = useAppDispatch();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isShowDismissPopup, setIsShowDismissPopup] = useBooleanState(false);
	const urlParams = parseUrlQuery(document.location);
	const [rejectReason, setRejectReason] = useState('');
	const fetchReferrals = useReferralsData({
		...mapQueryParams(urlParams),
	});

	const setLastViewed = async (tokens: string[]) => {
		try {
			await referBusinessesService.resetLastViewedReferItems(tokens);
		} catch (e) {
			ToastDispatcher.error('Reset "last viewed" error');
		}
	};

	const onDismissRefer = async (tokens: string[]) => {
		try {
			setIsSubmitting(true);
			await referBusinessesService.dismissReferral({tokens, rejectReason});

			Amplitude.track('Rejected_Referral');

			dispatch(setSideModal(false));
			dispatch(dismissReferItems(tokens));
			dispatch(removeCheckedItems(tokens));
			setLastViewed(tokens);
			ToastDispatcher.success(`Referral rejected.`, snackbarId => (
				<SnackActionButton
					id={snackbarId}
					buttonText="Undo"
					onClickAction={async () => {
						try {
							await referBusinessesService.restoreReferBusinessesItems(tokens);

							Amplitude.track('Rejected_Referral_Undo');

							await fetchReferrals();
						} catch (e) {
							ToastDispatcher.error(`Failed to restore.`);
						}
					}}
				/>
			));
		} catch (e) {
			ToastDispatcher.error(`Failed to dismiss. Please try again`);
		} finally {
			setIsSubmitting(false);
		}
	};

	const onAcceptRefer = async (tokens: string[]) => {
		try {
			setIsSubmitting(true);
			await referBusinessesService.acceptReferral(tokens);

			Amplitude.track('Accept_Referral');

			dispatch(setSideModal(false));
			setLastViewed(tokens);
			await fetchReferrals();
			ToastDispatcher.success(
				`${userFirstName} ${userLastName} has became your current client.`,
			);
		} catch (e) {
			ToastDispatcher.error(`Failed to accept. Please try again`);
		} finally {
			setIsSubmitting(false);
		}
	};

	const onScheduleMeeting = async (tokens: string[]) => {
		try {
			setIsSubmitting(true);
			await referBusinessesService.scheduleMeeting(tokens);
			dispatch(setSideModal(false));
			setLastViewed(tokens);
			await fetchReferrals();
			ToastDispatcher.success('Meeting scheduled successfully');
		} catch (e) {
			ToastDispatcher.error('Failed to schedule. Please try again');
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleRejectReasonChange = (value: string) => {
		setRejectReason(value);
	};

	return (
		<div className={`${s.referredToMeAdditionalInfo} referredToMeAdditionalInfo`}>
			<div className={`${s.btnContainer} btnContainer`}>
				<div className={s.btnGroup}>
					<PFButton
						type="button"
						variant="green"
						buttonHeight={40}
						disabled={isSubmitting}
						prefixIcon={
							<ReactSVG src={accept_button_icon} className={s.accept_button_icon} />
						}
						className={`${s.button} ${s.accept_button}`}
						onClick={() => onAcceptRefer([userToken])}>
						Accept & Invite
					</PFButton>
					<PFButton
						type="button"
						variant="secondary"
						buttonHeight={40}
						disabled={isSubmitting}
						prefixIcon={
							<ReactSVG
								src={scheduleMeetingIcon}
								className={s.scheduleMeeting_button_icon}
							/>
						}
						className={`${s.button} ${s.scheduleMeeting_button}`}
						onClick={() => onScheduleMeeting([userToken])}>
						Schedule a meeting
					</PFButton>
					<PFButton
						type="button"
						variant="alert"
						buttonHeight={40}
						disabled={isSubmitting}
						prefixIcon={
							<ReactSVG src={iconBlock} className={s.dismissRefer_button_icon} />
						}
						className={`${s.button} ${s.dismissRefer_button}`}
						onClick={() => setIsShowDismissPopup(true)}>
						Decline
					</PFButton>
				</div>
			</div>
			<div className={s.referredType}>
				<h6 className={s.additionalInfoTitle}>Type of business referred</h6>
				<div className={s.text}>
					{
						REFERRING_TO_BUSINESSES_OPTIONS_TYPES.find(
							option => option.type === referredType,
						)?.name
					}
				</div>
			</div>
			<div className={s.description}>
				<h6 className={s.additionalInfoTitle}>Description</h6>
				<PFSafeHtmlRenderer htmlContent={description} className={s.text} />
			</div>
			<DismissReferPopup
				visible={isShowDismissPopup}
				onCancel={() => setIsShowDismissPopup(false)}
				title="Dismiss referral?"
				onRejectReasonChange={value => handleRejectReasonChange(value)}
				onSubmit={() => onDismissRefer([userToken])}
			/>
		</div>
	);
};

export default AdditionalReferredToMeInfo;
