import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {ProfileFormContainer, SnackActionButton} from 'components';
import {DEFAULT_COMPANY_AVATAR, PORTAL_PATHS} from 'const';
import {isPossiblePhoneNumber} from 'libphonenumber-js';
import {PFButton, PFInput, PFPhoneInput, PFSelect} from 'shared/components';
import PFInputAvatarCrop from 'shared/components/PF-InputAvatarCrop';
import {loadLocationTokenOptions, useAddNewCompany} from 'shared/hooks';
import {
	generateValidator,
	isPhoneEmpty,
	renderLocation,
	ToastDispatcher,
	useErrorHelper,
	validations,
} from 'utils';

import FormInputKeys from '../EditCompanyData/constants';

import './style.scss';

const AddNewCompany = () => {
	const navigate = useNavigate();
	const addNewCompany = useAddNewCompany();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const errorHelper = useErrorHelper(navigate);
	const halfWidthClass = 'col-lg-6 mb-4';
	const fullWidthClass = 'col-12 mb-4';

	const formProps = useForm({
		mode: 'onChange',
	});
	const {
		register,
		trigger,
		getValues,
		watch,
		formState,
		setValue,
		control,
		unregister,
		setError,
		handleSubmit,
		...props
	} = formProps;

	const goBack = () => {
		navigate(-1);
	};

	const onSubmit = async valueObj => {
		const body = {
			name: valueObj[FormInputKeys.name],
			description: '',
			streetAddress: valueObj[FormInputKeys.streetAddress],
			postalCode: valueObj[FormInputKeys.postalCode],
			phoneNumber: isPhoneEmpty(valueObj[FormInputKeys.phoneNumber])
				? '' // ph# is not required, so send an empty string if it's empty.
				: valueObj[FormInputKeys.phoneNumber]?.formattedValue ||
				  valueObj[FormInputKeys.phoneNumber], // if the phoneNumber has been changd, we send a formatted ph#, otherwise the existing ph#.
			contactInfo: valueObj[FormInputKeys.contactInfo],
			administratorTokens: [],
			// accountAdministratorToken: null,
			locationToken: valueObj[FormInputKeys.locationToken],
			// token: companyToken,
			image: valueObj[FormInputKeys.companyLogo],
		};
		try {
			setIsSubmitting(true);
			const res = await addNewCompany(body);
			ToastDispatcher.success('New company added!', snackbarId => (
				<SnackActionButton
					id={snackbarId}
					buttonText="Open profile"
					onClickAction={() =>
						navigate(`${PORTAL_PATHS.OTHER_PATHS.COMPANY_PROFILE}#${res?.data?.token}`)
					}
				/>
			));
			navigate(PORTAL_PATHS.SIDEBAR.COMPANIES);
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmitting(false);
		}
	};

	const onChangeCompanyLogo = async image => {
		formProps.setValue(FormInputKeys.companyLogo, image);
	};

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === FormInputKeys.locationToken) {
				await trigger(FormInputKeys.locationToken);
			}
			if (name === FormInputKeys.name) {
				await trigger(FormInputKeys.name);
			}
			if (name === FormInputKeys.companyLogo) {
				await trigger(FormInputKeys.companyLogo);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	return (
		<div className="addNewCompany">
			<ProfileFormContainer title="Add New Company" goBack={goBack}>
				<form onSubmit={handleSubmit(onSubmit)} className="addNewCompany__form">
					<div className="row">
						<div className="col-12">
							<h4>Company Data</h4>
						</div>
						<div className={`${fullWidthClass} companyName`}>
							<PFInput
								{...register(FormInputKeys.name, {
									validate: generateValidator(
										true,
										validations.textFields.companyName,
									),
								})}
								// @ts-ignore
								showAsterisk
								label="Company Name"
								placeholder="Company Name"
								defaultValue=""
								type="text"
								error={
									formState.errors[FormInputKeys.name]?.message &&
									formState.errors[FormInputKeys.name]?.message
								}
							/>
						</div>
						<div className={`${halfWidthClass} locationToken`}>
							<PFSelect
								{...register(FormInputKeys.locationToken, {
									required: true,
								})}
								// @ts-ignore
								defaultValue=""
								showAsterisk
								label="Location"
								placeholder="Company location"
								disabled={false}
								isMulti={false}
								blurInputOnSelect
								closeMenuOnSelect
								isAsync
								onChange={value => {
									setValue(FormInputKeys.locationToken, value.token);
								}}
								getOptionValue={item => item.token}
								loadOptions={loadLocationTokenOptions}
								formatOptionLabel={option => renderLocation(option)}
								defaultOptions
								error={
									formState.errors[FormInputKeys.locationToken]?.message &&
									formState.errors[FormInputKeys.locationToken]?.message
								}
							/>
						</div>
						<div className={`${halfWidthClass} streetAddress`}>
							<PFInput
								{...register(FormInputKeys.streetAddress, {
									validate: generateValidator(
										false,
										validations.textFields.streetAddress,
									),
								})}
								// @ts-ignore
								showAsterisk={false}
								label="Street Address"
								placeholder="Street Address"
								defaultValue=""
								error={
									formState.errors[FormInputKeys.streetAddress]?.message &&
									formState.errors[FormInputKeys.streetAddress]?.message
								}
							/>
						</div>
						<div className={`${halfWidthClass} postalCode`}>
							<PFInput
								{...register(FormInputKeys.postalCode, {
									validate: generateValidator(
										false,
										validations.textFields.postalCode,
									),
								})}
								// @ts-ignore
								showAsterisk={false}
								label="Postal Code"
								placeholder="Postal Code"
								defaultValue=""
								error={
									formState.errors[FormInputKeys.postalCode]?.message &&
									formState.errors[FormInputKeys.postalCode]?.message
								}
							/>
						</div>
						<div className={`${halfWidthClass} phoneNumber`}>
							<PFPhoneInput
								name="phoneNumber"
								control={control}
								showAsterisk={false}
								label="Phone Number"
								defaultValue=""
								error={
									formState.errors[FormInputKeys.phoneNumber]?.message &&
									formState.errors[FormInputKeys.phoneNumber]?.message
								}
								labelSufix={false}
								variant={false}
								className=""
								hint={false}
								rules={{
									required: false,
									validate: value => {
										if (value?.countryCode) {
											if (isPhoneEmpty(value)) return true; // Ph# is not required, so if it's empty, then it's valid
											const k = isPossiblePhoneNumber(
												// This uses a google library to check if the ph# the user is entering is valid or invalid
												value?.value,
												value?.countryCode?.toUpperCase(),
											);
											return k || 'Please enter a valid phone number.'; // If valid, we're good, otherwise show the error message
										}
										return true;
									},
								}}
							/>
						</div>
						<div className={`${halfWidthClass} contactInfo`}>
							<PFInput
								{...register(FormInputKeys.contactInfo, {
									validate: generateValidator(false, validations.email),
								})}
								// @ts-ignore
								showAsterisk={false}
								label="Email"
								placeholder="Email"
								type="email"
								defaultValue=""
								error={
									formState.errors[FormInputKeys.contactInfo]?.message &&
									formState.errors[FormInputKeys.contactInfo]?.message
								}
							/>
						</div>
						<div className="companyLogo d-none">
							<PFInput
								{...formProps.register(FormInputKeys.companyLogo, {
									required: 'Logo is required',
									validate: value => {
										if (value === 'error') {
											return "The file you've uploaded is larger than 5MB. Please upload an image smaller than 5MB.";
										}
										if (value === 'error-type') {
											return 'HEIC image format not supported';
										}
										return true;
									},
								})}
								// @ts-ignore
								showAsterisk
								id="companyLogo"
								defaultValue={
									formProps.getValues(FormInputKeys.companyLogo) || null
								}
								error={
									formProps.formState.errors[FormInputKeys.companyLogo]
										?.message &&
									formProps.formState.errors[FormInputKeys.companyLogo]?.message
								}
							/>
						</div>
						<div className="col-12 companyLogo">
							<PFInputAvatarCrop
								label="Company logo"
								showAsterisk
								id="companyLogo1"
								onChange={onChangeCompanyLogo}
								src={formProps.getValues(FormInputKeys.companyLogo) || null}
								avatarSize={45}
								variant="rounded-square"
								defaultAvatar={DEFAULT_COMPANY_AVATAR}
								hint=""
								error={
									formProps.formState.errors[FormInputKeys.companyLogo]
										?.message &&
									formProps.formState.errors[FormInputKeys.companyLogo]?.message
								}
							/>
						</div>
					</div>
					<div className="addNewCompany__buttons-wrapper">
						<PFButton
							onClick={goBack}
							className="pf-profileEdit__form-button "
							variant="secondary"
							type="button">
							Cancel
						</PFButton>
						<PFButton
							disabled={!formState.isValid || isSubmitting}
							className="pf-profileEdit__form-button pf-profileEdit__form-button--submit"
							type="submit">
							Add
						</PFButton>
					</div>
				</form>
			</ProfileFormContainer>
		</div>
	);
};

export default AddNewCompany;
