import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css'; // Ensure correct Quill styles are imported
import './style.scss';

const PFTextarea = React.forwardRef(
  (
    {
      className,
      type,
      label,
      labelSufix,
      showAsterisk,
      icon,
      error,
      hint,
      onQuillChange,
      placeholder = 'Compose an epic...',
      defaultValue = '', // Set default value for pre-filled content
      modules = {
        toolbar: [
          ['bold', 'italic', 'underline', 'link'],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ background: [] }, { color: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ align: [] }],
        ],
      },
      formats = [
        'background',
        'bold',
        'color',
        'italic',
        'link',
        'size',
        'underline',
        'list',
        'indent',
        'align',
      ],
      ...props
    },
    ref
  ) => {
    const { quill, quillRef: localQuillRef } = useQuill({
      theme: 'snow',
      placeholder,
      modules,
      formats,
    });

    const isContentInitialized = useRef(false); // Track if defaultValue has been applied

    useEffect(() => {
      if (quill && !isContentInitialized.current) {
        // Set initial content only once
        if (defaultValue) {
          quill.clipboard.dangerouslyPasteHTML(defaultValue);
        }
        isContentInitialized.current = true; // Mark as initialized
      }

      const handleTextChange = () => {
        const plainText = quill.getText().trim();
        const deltaContent = quill.getSemanticHTML();; // Extract meaningful HTML content

        if (onQuillChange) {
          onQuillChange({ textdata: plainText, deltadata: deltaContent });
        }
      };

      quill?.on('text-change', handleTextChange);

      return () => {
        quill?.off('text-change', handleTextChange);
      };
    }, [quill, onQuillChange, defaultValue]);

    return (
      <div
        className={classNames(
          'pf-textarea',
          {
            'pf-textarea--error': error,
          },
          className
        )}
      >
        <div className="pf-textarea__label-wrapper">
          {label && (
            <label className="pf-textarea__label">
              {showAsterisk && <span className="pf-textarea__label-asterisk">*</span>}
              {label}
            </label>
          )}
          {labelSufix}
        </div>

        {type === 'quill' ? (
          <div ref={localQuillRef} className="pf-textarea__quill" />
        ) : (
          <div className="pf-textarea__field-wrapper">
            <textarea ref={ref} className="pf-textarea__field" {...props} />
            {icon && <span className="pf-textarea__field-icon">{icon}</span>}
          </div>
        )}

        <div
          className={classNames('pf-textarea__hint', {
            'pf-textarea__hint--error': error,
          })}
        >
          {error || hint}
        </div>
      </div>
    );
  }
);

PFTextarea.defaultProps = {
  type: 'text',
};

export default PFTextarea;
