import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {referBusinessesService} from 'api/services';
import {iconBlock, referBusinessImg, referClientImg, scheduleMeetingIcon} from 'assets';
import {EmptyLayout, SnackActionButton, UserCellItem} from 'components';
import {REFERRING_TO_BUSINESSES_OPTIONS_TYPES} from 'components/ReferBusinessPopup/components/constants';
import {DEFAULT_AVATAR_BLUE} from 'const';
import {ColumnProps, Table} from 'rsuite';
import {PFBadge, PFButton, PFSortArrow, PFTable, PFTableBar} from 'shared/components';
import {useBooleanState, useReferralsData} from 'shared/hooks';
import {useAppDispatch, useAppSelector} from 'store';
import {
	cleanedUpTableData,
	cleanUpReferralsData,
	dismissReferItems,
	removeCheckedItems,
	setCurrentPageTitle,
} from 'store/actions';
import {
	myProfileSelectors,
	selectReferralsData,
	selectReferralsDataFullCount,
	tableSelectors,
} from 'store/selectors';
import {
	Amplitude,
	isCompletedProfile,
	mapQueryParams,
	parseUrlQuery,
	ToastDispatcher,
	useErrorHelper,
} from 'utils';

import DismissReferPopup from './components/DismissReferPopup/DismissReferPopup';

import './style.scss';

type BarButtonPropsTypes = {
	action: () => void;
};

export const DismissReferButton: FC<BarButtonPropsTypes> = ({action}) => {
	return (
		<PFButton
			type="button"
			variant="white-alert"
			prefixIcon={
				<ReactSVG wrapper="span" className="dismissRefer-button-icon" src={iconBlock} />
			}
			className="dismissRefer-button"
			onClick={action}>
			<span className="button-text">Dismiss</span>
		</PFButton>
	);
};

type ColumnsProps = ColumnProps & {name: string; title: string};

const ReferredToMe = () => {
	const [isLoading, setIsLoading] = useState(true);
	const {Column, HeaderCell, Cell} = Table;
	const [update, setUpdate] = useState(true);
	const tableData = useAppSelector(selectReferralsData);
	const fullTableDataLength = useAppSelector(selectReferralsDataFullCount);
	const checkedKeys = useAppSelector(tableSelectors.selectTableCheckedData);
	const searchProp = useAppSelector(tableSelectors.selectTableSearchData);
	const resetProp = useAppSelector(tableSelectors.selectTableResetData);
	const reverseProp = useAppSelector(tableSelectors.selectTableReverseData);
	const urlParams = parseUrlQuery(document.location);
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const profile = useAppSelector(myProfileSelectors.selectMyProfileData); //! исспользутся для проверки окончена ли рега (надо ли)
	const [isShowDismissPopup, setIsShowDismissPopup] = useBooleanState(false);

	const params = {
		...mapQueryParams(urlParams),
	};
	const fetchReferrals = useReferralsData(params);
	const dispatch = useAppDispatch();
	const columns: ColumnsProps[] = [
		{
			name: 'name',
			title: 'Name',
			flexGrow: 1,
			minWidth: 216,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'contactInfo',
			title: 'Contact info',
			flexGrow: 1.5,
			minWidth: 190,
			align: 'left',
			sortable: false,
			verticalAlign: 'middle',
		},
		{
			name: 'typeOfBusinessReferred',
			title: 'Business Type',
			flexGrow: 1,
			minWidth: 150,
			align: 'left',
			sortable: false,
			verticalAlign: 'middle',
		},
		{
			name: 'action',
			title: 'Action',
			flexGrow: 1,
			minWidth: 90,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'whoReferred',
			title: 'Who referred',
			flexGrow: 2,
			minWidth: 180,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
	];
	const tableColumns = columns.map(item => {
		const switchParam = param => {
			switch (param) {
				case 'name':
					return (
						<Cell dataKey="name">
							{rowData => (
								<UserCellItem
									lastName={rowData?.userLastName}
									firstName={rowData?.userFirstName}
									avatarImage={rowData?.userProfileImage}
									defaultAvatar={DEFAULT_AVATAR_BLUE}
									avatarSize={40}
									isDeleted={false}
								/>
							)}
						</Cell>
					);
				case 'contactInfo':
					return (
						<Cell dataKey="contactInfo">
							{rowData => (
								<a className="pf-tableText" href={`mailto:${rowData?.email}`}>
									{rowData?.email}
								</a>
							)}
						</Cell>
					);
				case 'typeOfBusinessReferred':
					return (
						<Cell dataKey="typeOfBusinessReferred">
							{rowData => (
								<div className="pf-tableText">
									{
										REFERRING_TO_BUSINESSES_OPTIONS_TYPES.find(
											option => option.type === rowData?.type,
										)?.name
									}
								</div>
							)}
						</Cell>
					);
				case 'action':
					return (
						<Cell dataKey="action">
							{rowData => (
								<PFBadge
									variant={
										// eslint-disable-next-line no-nested-ternary
										rowData?.action === 'Accepted'
											? 'success'
											: rowData?.action === 'Scheduled Meeting'
											? 'progress'
											: 'white'
									}>
									{rowData?.action ? rowData.action : 'No action taken'}
								</PFBadge>
							)}
						</Cell>
					);
				case 'whoReferred':
					return (
						<Cell dataKey="whoReferred">
							{rowData => (
								<UserCellItem
									lastName={rowData?.recommendedByLastName}
									firstName={rowData?.recommendedByFirstName}
									avatarImage={rowData?.recommendedByProfileImage}
									avatarSize={20}
									isDeleted={false}
								/>
							)}
						</Cell>
					);
				default:
					return null;
			}
		};

		return (
			<Column
				key={item.name}
				flexGrow={item.flexGrow}
				minWidth={item.minWidth}
				align={item.align}
				sortable={item.sortable}
				verticalAlign={item.verticalAlign}>
				<HeaderCell>
					<div className="headerCellContent">
						<span>{item.title}</span>
						{item.sortable ? <PFSortArrow /> : null}
					</div>
				</HeaderCell>
				{switchParam(item.name)}
			</Column>
		);
	});

	const fetchMoreData = async (page, itemsCount, predicate, reverse, searchText) => {
		params.skip = (page - 1) * itemsCount;
		params.take = itemsCount;
		params.predicate = predicate;
		params.reverse = reverse;
		params.searchText = searchText;
		try {
			setIsLoading(true);
			await fetchReferrals();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const updateData = () => {
		setIsLoading(true);
		setUpdate(!update);
		setIsLoading(false);
	};

	const firstRender = async () => {
		dispatch(setCurrentPageTitle('Referred to Me'));
		try {
			setIsLoading(true);
			if (isCompletedProfile(profile?.advisor)) {
				await fetchReferrals();
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const emptyPageParam = {
		title: 'No one has been referred to you',
		subtitle:
			'Users of the Pocket Finance app that were referred to you by other advisors will appear here, as they need quick help from you.',
		note: (
			<div className="referBusiness__emptyPage">
				<div className="text">
					To refer your client to other advisors, select &#34;Refer Client&#34; in their
					profile:
				</div>
				<img src={referClientImg} alt="Refer Client" className="image" />
				<div className="text">
					...or click the &#34;Refer Business&#34; in the dropdown in the portal header:
				</div>
				<img src={referBusinessImg} alt="Refer Business" className="image" />
			</div>
		),
	};

	const searchEmptyParam = {
		className: '',
		description: `No results were found for your search.\n Refine your request`,
		isButton: false,
	};

	const onDismissRefer = async (tokens: string[]) => {
		try {
			const rejectReason = '';
			await referBusinessesService.dismissReferral({tokens, rejectReason});

			Amplitude.track('Dissmissed_Referral');

			dispatch(dismissReferItems(tokens));
			dispatch(removeCheckedItems(tokens));
			ToastDispatcher.success(`Referral dismissed.`, snackbarId => (
				<SnackActionButton
					id={snackbarId}
					buttonText="Undo"
					onClickAction={async () => {
						try {
							await referBusinessesService.restoreReferBusinessesItems(tokens);

							Amplitude.track('Dissmissed_Referral_Undo');
							firstRender();
						} catch (e) {
							ToastDispatcher.error(`Failed to restore.`);
						}
					}}
				/>
			));
		} catch (e) {
			ToastDispatcher.error(`Failed to dismiss. Please try again`);
		}
	};

	const tableBarButtons = [
		<DismissReferButton key="DismissReferButton" action={() => setIsShowDismissPopup(true)} />,
	];

	useEffect(() => {
		firstRender();
	}, [searchProp, resetProp, reverseProp, profile]);

	useEffect(() => {
		Amplitude.track('Opened_Referred_To_Me');
	}, []);

	useEffect(() => {
		return () => {
			dispatch(cleanUpReferralsData());
			dispatch(cleanedUpTableData());
		};
	}, []);

	return (
		<div className="referredToMe">
			<PFTableBar
				tableData={tableData}
				checkedKeys={checkedKeys}
				tableBarButtons={tableBarButtons}
				modal="referredToMe"
			/>
			<PFTable
				tableColumns={tableColumns}
				tableData={tableData}
				isLoading={isLoading}
				modal="referredToMe"
				fetchMoreData={fetchMoreData}
				fullTableDataLength={fullTableDataLength}
				checkedKeys={checkedKeys}
				update={update}
				updateCallback={() => {}}
				parseUrlQuery={parseUrlQuery}
				updateDataForUseOnModal={updateData}
				emptyComponent={
					<EmptyLayout
						emptyPageParam={emptyPageParam}
						searchEmptyParam={searchEmptyParam}
					/>
				}
			/>
			<DismissReferPopup
				visible={isShowDismissPopup}
				onCancel={() => setIsShowDismissPopup(false)}
				title="Reject referral?"
				onSubmit={() => onDismissRefer(checkedKeys)}
			/>
		</div>
	);
};

export default ReferredToMe;
