import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {advisorClientsService, financialReportsService} from 'api/services';
import {
	currentButtonsIcon,
	currentButtonsIconMobile,
	currentStatusIcon,
	currentStatusIconMobile,
	currentTableIcon,
	currentTableIconMobile,
	quitTeamIcon,
	reportRequestIcon,
} from 'assets';
import {CustomTagCellItem, EmptyLayout, OpportunityItem, UserCellItem} from 'components';
import moment from 'moment';
import {ColumnProps, Table} from 'rsuite';
import {PFButton, PFSortArrow, PFTable, PFTableBar} from 'shared/components';
import {useBooleanState, useCurrentData, useLocations} from 'shared/hooks';
import {useAppDispatch, useAppSelector} from 'store';
import {
	cleanedUpTableData,
	cleanUpCurrentData,
	removeCheckedItems,
	setCurrentPageTitle,
} from 'store/actions';
import {
	myProfileSelectors,
	selectCurrentData,
	selectCurrentFullCount,
	tableSelectors,
} from 'store/selectors';
import {
	Amplitude,
	checkExtendedProfileView,
	findByToken,
	getOpportunityIcon,
	isCompletedProfile,
	mapQueryParams,
	parseUrlQuery,
	ToastDispatcher,
	useErrorHelper,
} from 'utils';

import {ScheduleMeetingButton} from '../Leads';

import './style.scss';

type ReportButtonPropsTypes = {
	action: () => void;
	disabled: boolean;
};

const RequestReportButton: FC<ReportButtonPropsTypes> = ({action, disabled}) => {
	return (
		<PFButton
			type="button"
			prefixIcon={
				<ReactSVG
					wrapper="span"
					className="pf-icon-request-report"
					src={reportRequestIcon}
				/>
			}
			disabled={disabled}
			className="pf-request-report-button"
			onClick={action}>
			<span className="button-text">Request Report</span>
		</PFButton>
	);
};

const QuitTeamButton: FC<ReportButtonPropsTypes> = ({action, disabled}) => {
	return (
		<PFButton
			type="button"
			variant="alert"
			prefixIcon={
				<ReactSVG wrapper="span" className="pf-icon-quit-from-team" src={quitTeamIcon} />
			}
			disabled={disabled}
			className="pf-quit-from-team-button"
			onClick={action}>
			<span className="button-text">Quit Clients’ Teams</span>
		</PFButton>
	);
};

type ColumnsProps = ColumnProps & {name: string; title: string};

type Location = any; // Replace 'any' with the actual type if known
type LoadLocationsResult = {
	options: Location[];
	hasMore: boolean;
};
const fetchLocations = useLocations();
const loadLocations = async (
	Query: string,
	loadedOptions: Location[] = [],
	skip = 0,
	take = 5000,
): Promise<LoadLocationsResult> => {
	const results = await fetchLocations({
		Query,
		skip,
		take,
	});

	const {body: options, count, currentCount} = results;
	const newLoadedOptions = [...loadedOptions, ...options];

	localStorage.setItem('locationTokenOptions', JSON.stringify(newLoadedOptions));

	if (skip + options.length < 32205) {
		await loadLocations(Query, newLoadedOptions, skip + 5000);
	}

	return {
		options: newLoadedOptions,
		hasMore: currentCount !== 0 && count > newLoadedOptions.length,
	};
};

const Current = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmittingRequestReport, setIsSubmittingRequestReport] = useState(false);
	const {Column, HeaderCell, Cell} = Table;
	const [update, setUpdate] = useState(true);
	const [updateParam, setUpdateParam] = useState('');
	const tableData = useAppSelector(selectCurrentData);
	const fullTableDataLength = useAppSelector(selectCurrentFullCount);
	const checkedKeys = useAppSelector(tableSelectors.selectTableCheckedData);
	const searchProp = useAppSelector(tableSelectors.selectTableSearchData);
	const resetProp = useAppSelector(tableSelectors.selectTableResetData);
	const reverseProp = useAppSelector(tableSelectors.selectTableReverseData);

	const [isSubmittingScheduleMeeting, setIsSubmittingScheduleMeeting] = useBooleanState(false);
	const [isQuittingFromTeam, setIsQuittingFromTeam] = useBooleanState(false);

	const calendlyLink = useAppSelector(myProfileSelectors.selectMyCalendlyLink);

	const urlParams = parseUrlQuery(document.location);
	const navigate = useNavigate();
	const errorHelper = useErrorHelper(navigate);
	const profile = useAppSelector(myProfileSelectors.selectMyProfileData);

	const params = {
		type: 2,
		...mapQueryParams(urlParams),
	};
	const fetchCurrentData = useCurrentData(params);
	const dispatch = useAppDispatch();

	const columns: ColumnsProps[] = [
		{
			name: 'user',
			title: 'User',
			flexGrow: 2,
			minWidth: 216,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'opportunities',
			title: 'Opportunities',
			flexGrow: 3,
			minWidth: 220,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'tags',
			title: 'Tags',
			flexGrow: 2,
			minWidth: 224,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'last viewed',
			title: 'Last viewed',
			flexGrow: 1,
			minWidth: 180,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
		{
			name: 'province',
			title: 'Province',
			flexGrow: 1,
			minWidth: 180,
			align: 'left',
			sortable: true,
			verticalAlign: 'middle',
		},
	];

	const tableColumns = columns.map((item, index) => {
		const switchParam = param => {
			switch (param) {
				case 'user':
					return (
						<Cell dataKey="LastName">
							{rowData => (
								<UserCellItem
									lastName={rowData?.lastName}
									firstName={rowData?.firstName}
									avatarImage={rowData?.profileImage}
									avatarSize={40}
									isDeleted={false}
									restoreToken={rowData?.token}
								/>
							)}
						</Cell>
					);
				case 'opportunities':
					return (
						<Cell dataKey="Opportunities">
							{rowData => {
								const isExtendedProfile = checkExtendedProfileView(
									rowData?.advisorClientSpecializationTypes,
								);

								if (!isExtendedProfile) {
									return <div className="pf-reportsCellItem pf-tableText">-</div>;
								}

								return (
									<OpportunityItem
										title={rowData?.riskAndOpportunity?.text}
										date={
											rowData?.riskAndOpportunity?.createdAt
												? new Date(rowData?.riskAndOpportunity?.createdAt)
												: null
										}
										icon={getOpportunityIcon(rowData?.riskAndOpportunity?.type)}
									/>
								);
							}}
						</Cell>
					);
				case 'tags':
					return (
						<Cell dataKey="Tags">
							{rowData => (
								<CustomTagCellItem
									customClientTags={rowData?.customClientTags}
									token={rowData?.token}
								/>
							)}
						</Cell>
					);
				case 'last viewed':
					return (
						<Cell dataKey="LastViewed">
							{rowData => (
								<div className="pf-tableText">
									{rowData?.lastViewed
										? moment.utc(rowData?.lastViewed).local().fromNow(true)
										: 'Never'}
								</div>
							)}
						</Cell>
					);
				case 'province':
					return (
						<Cell dataKey="Province">
							{rowData => (
								<div className="pf-reportsCellItem pf-tableText">
									{rowData?.province ? (
										<span className="pf-reportsText">{rowData?.province}</span>
									) : (
										'-'
									)}
								</div>
							)}
						</Cell>
					);
				default:
					return null;
			}
		};

		return (
			<Column
				key={item.name}
				flexGrow={item.flexGrow}
				minWidth={item.minWidth}
				align={item.align}
				sortable={item.sortable}
				verticalAlign={item.verticalAlign}>
				<HeaderCell>
					<div className="headerCellContent">
						<span>{item.title}</span>
						<PFSortArrow />
					</div>
				</HeaderCell>
				{switchParam(item.name)}
			</Column>
		);
	});

	const fetchMoreData = async (page, itemsCount, predicate, reverse, searchText) => {
		params.skip = (page - 1) * itemsCount;
		params.take = itemsCount;
		params.predicate = predicate;
		params.reverse = reverse;
		params.searchText = searchText;
		try {
			setIsLoading(true);
			await fetchCurrentData();
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const updateData = () => {
		setIsLoading(true);
		setUpdate(!update);
		setIsLoading(false);
	};

	const fetchRequestReports = async checkedTokens => {
		// ! тут надо отправлять только тем у кого подключены connectedAccountsCount, и уведомлять кому отправлено а кому нет
		try {
			setIsSubmittingRequestReport(true);
			await Promise.all(
				checkedTokens.map(item => financialReportsService.requestReport({userToken: item})),
			)
				.then(response => {
					updateData();
					ToastDispatcher.success('Report requested');
				})
				.catch(e => {
					errorHelper(e);
				});

			Amplitude.track('Requested_Report', {
				source: 'Current',
			});
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsSubmittingRequestReport(false);
		}
	};

	const updateCallback = () => {
		if (updateParam === 'blocked') {
			checkedKeys.forEach(key => {
				const foundBlockedCompany = findByToken(tableData, key);
				if (foundBlockedCompany) foundBlockedCompany.isBlocked = true;
			});
			setUpdateParam('');
		} else if (updateParam === 'unblocked') {
			checkedKeys.forEach(key => {
				const foundBlockedCompany = findByToken(tableData, key);
				if (foundBlockedCompany) foundBlockedCompany.isBlocked = false;
			});
			setUpdateParam('');
		}
	};

	const sendScheduleMeeting = async (userTokens: string[]) => {
		try {
			setIsSubmittingScheduleMeeting(true);
			await advisorClientsService.sendScheduleMeeting(userTokens);

			Amplitude.track('Scheduled_Meeting', {
				source: 'Current Table',
				clients: userTokens,
			});

			ToastDispatcher.success(
				'The client will receive your calendar link to suggest the meeting date and time.',
			);
		} catch (e) {
			ToastDispatcher.error(`Failed to schedule a meeting. Please check your calendar link.`);
			throw e;
		} finally {
			setIsSubmittingScheduleMeeting(false);
		}
	};

	const quitFromTeam = async (userTokens: string[]) => {
		try {
			setIsQuittingFromTeam(true);
			await advisorClientsService.removeClientsFromTeam({
				ClientTokens: userTokens,
			});

			await fetchCurrentData();

			dispatch(removeCheckedItems(userTokens));

			ToastDispatcher.success('You have left client’s team.');
		} catch (e) {
			errorHelper(e, false, 'Unable to quit client’s team. Please try again.');
			throw e;
		} finally {
			setIsQuittingFromTeam(false);
		}
	};

	/* TODO: sent to the server and received from the server as "Specializations". On the front side, the field is called "Roles available". */
	const firstRender = async () => {
		dispatch(setCurrentPageTitle('Current'));

		try {
			setIsLoading(true);
			if (isCompletedProfile(profile?.advisor)) {
				await fetchCurrentData();
			}
		} catch (e) {
			errorHelper(e);
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const emptyPageParam = {
		title: 'Don’t See Your Clients Here?',
		subtitle: 'Follow the steps below to bring your clients here from your Leads.',
		steps: [
			{
				description: (
					<div>
						Go to the Leads tab and <b>click the client’s name</b> if their status is
						“Invitation to Team”:
					</div>
				),
				content: (
					<picture>
						<source media="(max-width: 992px)" srcSet={currentStatusIconMobile} />
						<img src={currentStatusIcon} alt="Portal table" />
					</picture>
				),
			},
			{
				description: (
					<div>
						<b>Click “Accept”:</b>
					</div>
				),
				content: (
					<picture>
						<source media="(max-width: 992px)" srcSet={currentButtonsIconMobile} />
						<img src={currentButtonsIcon} alt="Portal table" />
					</picture>
				),
			},
			{
				description: (
					<div>
						<b>Voilà!</b> The client has been moved to your Current tab!
					</div>
				),
				content: (
					<picture>
						<source media="(max-width: 992px)" srcSet={currentTableIconMobile} />
						<img src={currentTableIcon} alt="Portal table" />
					</picture>
				),
			},
		],
		note: (
			<p>
				<b>Clients with other statuses</b> (e.g. “Request to Connect” or “Request Approved”)
				can’t be added to the Current list immediately; you need to accept their request and
				contact them first, then you should ask them to add you to their Team.
			</p>
		),
	};
	const searchEmptyParam = {
		variant: 'secondary',
		className: '',
		pageName: 'Current',
		buttonText: 'Back to Current',
	};
	const tableBarButtons = [
		<RequestReportButton
			key={1}
			disabled={isSubmittingRequestReport}
			action={() => fetchRequestReports(checkedKeys)}
		/>,
		<ScheduleMeetingButton
			key="ScheduleMeetingButton"
			disabled={isSubmittingScheduleMeeting || !calendlyLink}
			action={() => sendScheduleMeeting(checkedKeys)}
		/>,
		<QuitTeamButton
			key="QuitTeamButton"
			disabled={isQuittingFromTeam}
			action={() => quitFromTeam(checkedKeys)}
		/>,
	];

	useEffect(() => {
		firstRender();
	}, [searchProp, resetProp, reverseProp, profile]);

	useEffect(() => {
		Amplitude.track('Opened_Current');
	}, []);

	useEffect(() => {
		return () => {
			dispatch(cleanUpCurrentData());
			dispatch(cleanedUpTableData());
		};
	}, []);

	const hasLoadedRef = useRef(false);

	useEffect(() => {
		const storedOptions = localStorage.getItem('locationTokenOptions');

		if (!storedOptions || JSON.parse(storedOptions).length !== 32202) {
			if (!hasLoadedRef.current) {
				hasLoadedRef.current = true; // Mark as loaded
				loadLocations('', []); // Start loading data
			}
		}
	}, []);

	return (
		<>
			<PFTableBar
				tableData={tableData}
				checkedKeys={checkedKeys}
				tableBarButtons={tableBarButtons}
				modal="current"
			/>
			<PFTable
				tableColumns={tableColumns}
				tableData={tableData}
				isLoading={isLoading}
				modal="current"
				fetchMoreData={fetchMoreData}
				fullTableDataLength={fullTableDataLength}
				checkedKeys={checkedKeys}
				update={update}
				updateCallback={updateCallback}
				updateDataForUseOnModal={updateData}
				parseUrlQuery={parseUrlQuery}
				emptyComponent={
					<EmptyLayout
						emptyPageParam={emptyPageParam}
						searchEmptyParam={searchEmptyParam}
					/>
				}
			/>
		</>
	);
};

export default Current;

// const mapStateToProps = state => {
// 	return {
// 		current: state.current,
// 		table: state.table,
// 	};
// };
//
// export default connect(mapStateToProps)(Current);
// export default withRedirect(
//   () => true,
//   COMMON_PATHS.ERROR
// )(connect(mapStateToProps)(Current));
